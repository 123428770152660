import cx from 'classnames'
import { useEffect, useRef } from 'react'

import ImgNotFound from '../../assets/images/NotFoundBox.png'
import { Spinner } from '../../components/Spinner'
import { usePermission } from '../../hooks/usePermission'
import { INote } from '../../services/notes'
import { ACCEPTED_FILE_IMAGE } from '../../utils/files'
import { PERMISSIONS, PERMISSIONS_CREATE } from '../../utils/permission'
import { DropzoneFull } from '../Form/DropzoneFull'
import { ComponentPermisison } from '../Permission/ComponentPermission'
import { NoteComposer } from './Composer'
import NotesContextProvider, { useNotesCtx } from './Context'
import { NoteHeader } from './Header'
import { NoteItem } from './Note'

type Props = {
  notes?: INote[]
  resourceId: number
  resourceType: string
  showFilter?: boolean
  className?: string
  contentClassName?: string
  onScroll?: (event: any) => void
}

type PropsWrapper = {
  className?: string
  contentClassName?: string
  onScroll?: (event: any) => void
}
export const Notes = ({
  className,
  contentClassName,
  onScroll,
  ...props
}: Props) => {
  return (
    <NotesContextProvider {...props}>
      <NoteWrapper
        className={className}
        contentClassName={contentClassName}
        onScroll={onScroll}
      />
    </NotesContextProvider>
  )
}

const NoteWrapper = ({
  className = '',
  contentClassName = '',
  onScroll = () => undefined,
}: PropsWrapper) => {
  const refScrollBar = useRef<HTMLDivElement | null>(null)
  const refContent = useRef<HTMLDivElement | null>(null)

  const {
    listNotes,
    filterByAccount,
    resourceId,
    resourceType,
    pinnedNote,
    scrollId,
    detailNoteId,
    handleInitNotes,
    dispatch,
    loading,
    fetching,
  } = useNotesCtx()
  const { canCreate, canUpdate, canDelete } = usePermission({
    name: PERMISSIONS.NOTE,
  })

  const renderNotFound = () => {
    return (
      <div className='flex-1 w-full flex justify-center items-center px- 4'>
        <div className='max-w-[20rem]'>
          <img src={ImgNotFound} className='mb-4' alt='empty data' />
          <p className='text-black-400 mb-0'>
            There are no note yet, add your first note below
          </p>
        </div>
      </div>
    )
  }

  const handleDropFile = (files: File[]) => {
    if (!loading) {
      dispatch({
        listAttachments: files,
      })
    }
  }

  const handleScrollToEnd = () => {
    setTimeout(() => {
      if (refScrollBar?.current) {
        refScrollBar.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        })
      }
    }, 200)
  }

  const handelScrollToNote = (id: number) => {
    const elem = document.getElementById(`note-${id}`)
    if (elem) {
      elem.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
    }
  }

  const initNotes = async (updateByFilter = false) => {
    await handleInitNotes()
  }

  const renderPinnedNote = (data: INote) => (
    <div className='px-6 bg-blue-400 pt-4'>
      <NoteItem
        note={data}
        isPinned={true}
        canUpdate={canUpdate}
        canDelete={canDelete}
      />
    </div>
  )
  useEffect(() => {
    if (scrollId) {
      handelScrollToNote(scrollId)
    }
  }, [scrollId])

  useEffect(() => {
    if (detailNoteId) {
      setTimeout(() => {
        handelScrollToNote(detailNoteId)
      }, 500)
    }
  }, [detailNoteId])

  useEffect(() => {
    initNotes(Boolean(filterByAccount))
  }, [filterByAccount])

  const isEmptyList = listNotes.length === 0
  return (
    <div
      className={cx('flex flex-col justify-between h-full flex-1', className)}
    >
      {listNotes.length > 0 && <NoteHeader />}
      {pinnedNote && renderPinnedNote(pinnedNote)}
      {fetching && <Spinner />}
      <div
        className={cx(
          'custom-scrollbar h-full overflow-y-auto flex-1',
          contentClassName,
        )}
        ref={refContent}
        onScroll={onScroll}
      >
        <ComponentPermisison type={PERMISSIONS_CREATE} name={PERMISSIONS.FILE}>
          <DropzoneFull
            resourceId={resourceId}
            resourceType={resourceType}
            onlyView={true}
            onDropEnd={handleDropFile}
            optionDropzone={{
              accept: ACCEPTED_FILE_IMAGE,
            }}
          />
        </ComponentPermisison>
        <div className={cx(isEmptyList && 'flex flex-col h-full')}>
          {isEmptyList ? (
            renderNotFound()
          ) : (
            <div className='px-6 pt-4'>
              {listNotes.map(item => (
                <NoteItem
                  note={item}
                  key={item.id}
                  canUpdate={canUpdate}
                  canDelete={canDelete}
                />
              ))}
            </div>
          )}
        </div>
        <div ref={refScrollBar} />
      </div>
      {canCreate && !fetching && (
        <NoteComposer onAddedNote={handleScrollToEnd} />
      )}
    </div>
  )
}
