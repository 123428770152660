import { Image } from '../../../../components'
import {
  getExtFile,
  getFileType,
  MINETYPE_IMAGE_SVG,
} from '../../../../utils/files'
import { downloadFile } from '../../../../utils/functions'
import { useFileDetailCtx } from './Context'

export const GalleryMain = () => {
  const { file, onPrevFile, onNextFile, listRelatedFiles } = useFileDetailCtx()
  const extFile = getExtFile(file.file_name)
  const renderDefaultThumbnail = () => (
    <div className='h-full flex items-center relative z-100 text-white text-body bg-black'>
      Can’t preview this file. Please&nbsp;
      <span
        onClick={() => downloadFile(file.url, `${file.name}.${extFile}`)}
        className='text-primary-900 underline cursor-pointer'
      >
        Download
      </span>
      &nbsp; to view
    </div>
  )
  const renderContent = () => {
    const type = getFileType(file.mime_type)
    switch (type) {
      case 'video': {
        return (
          <video
            src={file.url}
            controls
            className='max-w-full z-100 h-full relative'
            key={`main-${file.id}`}
          />
        )
      }
      case 'audio': {
        return (
          <div className='h-full flex items-center relative z-100 max-w-[40rem] w-full'>
            <audio
              src={file.url}
              controls
              className='w-full'
              key={`main-${file.id}`}
            />
          </div>
        )
      }
      case 'image': {
        if (file.mime_type === MINETYPE_IMAGE_SVG) {
          return (
            <div className='h-full flex items-center relative z-100'>
              <Image
                src={file?.url || file.preview_url}
                className='max-h-full w-full h-auto'
                showLoading={true}
              />
            </div>
          )
        }
        return (
          <div className='h-full flex items-center relative z-100'>
            <Image
              src={file?.url || file.preview_url}
              className='z-100 max-h-full'
              showLoading={true}
            />
          </div>
        )
      }
      case 'pdf': {
        return (
          <embed
            src={`${file.url}#navpanes=0`}
            type='application/pdf'
            width='100%'
            height='100%'
            className='z-[100]'
          />
        )
      }
      default: {
        return renderDefaultThumbnail()
      }
    }
  }
  const isHasPagination = listRelatedFiles.length > 0
  return (
    <div className='p-10 flex flex-1 justify-center items-center w-full relative z-1 h-full'>
      {isHasPagination && (
        <div
          className='absolute left-0 top-10 bottom-10 right-1/2 z-0 cursor-pointer flex items-center pl-4'
          onClick={onPrevFile}
          id='media-slider-prev'
        >
          <div className='font-icon-arrow_left text-[2.5rem] text-white cursor-pointer' />
        </div>
      )}
      {isHasPagination && (
        <div
          className='absolute right-0 top-10 bottom-10 left-1/2 z-0 cursor-pointer flex items-center pr-4 justify-end'
          onClick={onNextFile}
          id='media-slider-next'
        >
          <div className='font-icon-arrow_right text-[2.5rem] text-white cursor-pointer' />
        </div>
      )}
      {renderContent()}
    </div>
  )
}
