import { omit } from 'lodash'

import { useLocalStorage } from './useLocalStorage'

type TOptions = {
  disable?: boolean
}
interface Props<T> {
  localFilters: T
  setLocalFilters: (filters: Partial<T>) => void
  resetLocalFilters: () => void
}
export const useLoadFilters = <T>(
  key: string,
  options?: TOptions,
): Props<T> => {
  const [filters, setFilters] = useLocalStorage<Record<string, any>>(
    'RF_LOCAL_FILTERS',
    {},
  )
  const handleSetFilters = (data: Record<string, any>) => {
    if (options?.disable) {
      return
    }
    const result: Record<string, any> = {}
    const resultLocal = JSON.parse(
      window.localStorage.getItem('RF_LOCAL_FILTERS') || '',
    )
    const resultLocalByKey = resultLocal?.[key] ? resultLocal[key] : {}
    const mergeResult = {
      ...resultLocalByKey,
      ...data,
    }
    Object.keys(mergeResult).forEach(k => {
      if (mergeResult[k] !== null) {
        result[k] = mergeResult[k]
      }
    })
    setFilters({
      ...resultLocal,
      [key]: result,
    })
  }
  const handleResetFilters = () => {
    if (!key) {
      return
    }
    const newFilter = omit(filters, [key])
    setFilters(newFilter)
  }

  return {
    localFilters: key && filters[key] ? filters[key] : {},
    setLocalFilters: handleSetFilters,
    resetLocalFilters: handleResetFilters,
  }
}
