import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import shallow from 'zustand/shallow'

import { Overlay, Popover, Portal, Tooltip } from '../../components'
import { Avatar } from '../../components/Avatar'
import { Spinner } from '../../components/Spinner'
import { PATHNAME } from '../../configs/routes'
import { useAsync } from '../../hooks'
import { AuthUser, logout } from '../../services/auth'
import { ModalMyProfile } from '../../shared/Modals/Auth/Profile'
import { ModalGlobalSearch } from '../../shared/Modals/Search'
import { ComponentPermisison } from '../../shared/Permission/ComponentPermission'
import useStore, { Store } from '../../store'
import { PERMISSIONS, PERMISSIONS_VIEW } from '../../utils/permission'
import { ContentMainMenu } from './MainMenu'
import { NotificationMenu } from './NotificationMenu'

const mapState = ({ auth }: Store) => ({
  user: auth.currentUser,
})

export const TopRightMenu = () => {
  const { user } = useStore(mapState, shallow)
  const [isOpenMobileMenu, setOpenMobileMenu] = useState(false)
  const [isOpenSearch, setOpenSearch] = useState(false)
  const [isOpenMenu, setOpenMenu] = useState(false)
  const renderMainMenu = (onClose: () => void) => (
    <Popover.Content
      onClose={onClose}
      className='w-[calc(100vw_-_2rem)] sm:w-[39rem] !p-0 shadow-dropdown h-auto max-h-[calc(100vh_-_6rem)] custom-scrollbar overflow-auto'
    >
      <ContentMainMenu onClose={onClose} />
    </Popover.Content>
  )

  const handleOpenGlobalSearch = (e: KeyboardEvent) => {
    const target = e.target as HTMLElement
    let tagName = target.tagName
    if (typeof tagName !== 'string') {
      tagName = ''
    }
    tagName = tagName.toLowerCase()
    if (['input', 'textarea', 'select', 'button'].includes(tagName)) {
      return
    }
    const keyShortCut = e.key.toLowerCase()
    if ((e.ctrlKey || e.metaKey) && keyShortCut === 'i' && !isOpenSearch) {
      setOpenSearch(true)
    }
  }

  const handleActionMobileMenu = (action: boolean) => {
    const parentMenu = document.getElementsByClassName('app__top-menu')[0]
    if (parentMenu) {
      action
        ? parentMenu.classList.add('open')
        : parentMenu.classList.remove('open')
    }
    setOpenMobileMenu(action)
  }

  useEffect(() => {
    window.addEventListener('keydown', handleOpenGlobalSearch)
    return () => {
      window.removeEventListener('keydown', handleOpenGlobalSearch)
    }
  }, [])

  return (
    <>
      <motion.div
        className='w-80 h-full bg-white border-r border-[#eeeff0] fixed top-[3.25rem] z-[9999] shadow-sm block sm:hidden'
        initial={{
          left: -320,
        }}
        animate={{ left: isOpenMobileMenu ? 0 : -320 }}
      >
        <ContentMainMenu onClose={() => handleActionMobileMenu(false)} />
      </motion.div>
      <div className='item flex gap-[1.625rem] items-center text-[1.125rem] ml-4 relative'>
        <span
          className='font-icon font-icon-menu text-black-800 text-base leading-none cursor-pointer block sm:hidden'
          onClick={() => handleActionMobileMenu(!isOpenMobileMenu)}
        />
        <Popover
          content={onClose => renderMainMenu(onClose)}
          visible={isOpenMenu}
          onVisibleChange={v => setOpenMenu(v)}
          className='hidden items-center sm:flex'
        >
          <span className='font-icon font-icon-menu text-black-800 text-base leading-none cursor-pointer'></span>
        </Popover>

        <Tooltip
          content='Global Search (ctrl + i)'
          placement='right'
          className='leading-none'
        >
          <span
            className='font-icon-search text-black-800 text-[1.125rem] cursor-pointer'
            onClick={() => setOpenSearch(true)}
          />
        </Tooltip>
        <NotificationMenu />
        <ComponentPermisison name={PERMISSIONS.SETTING} type={PERMISSIONS_VIEW}>
          <Link to={`/${PATHNAME.settings}`}>
            <span className='font-icon-setting' />
          </Link>
        </ComponentPermisison>
        <ProfileMenu user={user} />
      </div>
      {isOpenMenu && (
        <Portal>
          <Overlay
            lockScroll
            className='bg-popup-overlay top-[3.25rem] block sm:hidden'
          />
        </Portal>
      )}
      <AnimatePresence initial={false}>
        {isOpenSearch && (
          <ModalGlobalSearch onClose={() => setOpenSearch(false)} />
        )}
      </AnimatePresence>
    </>
  )
}

export const ProfileMenu = ({ user }: { user: AuthUser | null }) => {
  const [isOpenProfile, setOpenProfile] = useState(false)
  const { execute, isLoading, isSuccess } = useAsync({ showNotifOnError: true })
  const handleLogout = () => {
    execute(logout())
      .then(() => (window.location.href = window.location.origin + '/login'))
      .catch(console.error)
  }

  const renderPopoverProfile = (onClose: () => void) => (
    <Popover.Content
      onClose={onClose}
      className='w-[12rem] !p-0 shadow-dropdown'
    >
      <div className='p-2'>
        <div
          onClick={() => {
            setOpenProfile(true)
            onClose()
          }}
          className='px-4 py-2 flex gap-2 items-center hover:bg-white-900 rounded-lg cursor-pointer'
        >
          <span className='font-icon-user' />
          <span>My Profile</span>
        </div>
        <div className='cursor-pointer border-t border-t-sepearation-400 pt-2 mt-2'>
          <div
            className='text-red-900  hover:bg-white-900 rounded-lg px-4 py-2 font-medium'
            onClick={handleLogout}
          >
            Logout
          </div>
        </div>
      </div>
    </Popover.Content>
  )

  return (
    <>
      <Popover content={renderPopoverProfile}>
        <Avatar
          size='small'
          hashId={`user-${user?.id}`}
          className='bg-separation-200 cursor-pointer'
          src={user?.avatar_preview_url}
        />
      </Popover>
      {(isLoading || isSuccess) && (
        <Portal>
          <Overlay zIndex={9999} lockScroll className='bg-white/60'>
            <Spinner.Fullscreen />
          </Overlay>
        </Portal>
      )}
      <AnimatePresence initial={false}>
        {isOpenProfile && user?.id && (
          <ModalMyProfile
            onClose={() => setOpenProfile(false)}
            userId={user.id}
          />
        )}
      </AnimatePresence>
    </>
  )
}
