import { Button, Popover } from '../../../../../components'
import { usePermission } from '../../../../../hooks/usePermission'
import { PopoverMenuFile } from '../../../../../pages/Files/Main/PopoverMenuFile'
import { getExtFile } from '../../../../../utils/files'
import { downloadFile } from '../../../../../utils/functions'
import { PERMISSIONS } from '../../../../../utils/permission'
import { useFileDetailCtx } from '../Context'

export const HeaderPopoverMenu = () => {
  const { file, dispatch, onUpdateFile } = useFileDetailCtx()
  const { canDelete, canUpdate } = usePermission({ name: PERMISSIONS.FILE })
  const extFile = getExtFile(file.file_name)
  return (
    <Popover
      placement='bottom'
      content={onClose => (
        <PopoverMenuFile
          file={file}
          onClose={onClose}
          onRenameFile={
            canUpdate
              ? () => {
                  onClose()
                  dispatch({ openRenameFile: true })
                }
              : undefined
          }
          onFavorite={
            canUpdate
              ? (status: boolean) => {
                  onClose()
                  onUpdateFile({ is_favorited: status })
                }
              : undefined
          }
          onManageAccess={
            canUpdate
              ? () => {
                  onClose()
                  dispatch({ openManageAccess: true })
                }
              : undefined
          }
          onDownload={() => {
            onClose()
            downloadFile(file.url, `${file.name}.${extFile}`)
          }}
          onDelete={
            canDelete
              ? () => {
                  onClose()
                  dispatch({ openDeleteFile: true })
                }
              : undefined
          }
        />
      )}
    >
      <Button
        variant='primary'
        className='bg-[#45484F] !rounded-lg'
        innerClassName='!px-2'
      >
        <span className='font-icon-more text-white' />
      </Button>
    </Popover>
  )
}
