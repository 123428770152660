export interface OptionValue {
  value: number | null
  label: string | null
}

export interface OptionValueString extends Omit<OptionValue, 'value'> {
  value: string | null
}

export interface OptionValueTax extends OptionValue {
  sales_tax: string | null
}
export interface OptionValueTag {
  value: string | null
  label: string | null
  key: string | null
}

export const defaultOptionValue = {
  value: null,
  label: null,
}

export const defaultOptionValueTax: OptionValueTax = {
  value: null,
  label: null,
  sales_tax: null,
}

export const textHasWhiteSpace = (text: string) => /\s/.test(text)

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const validateIpAddress = (ip: string) =>
  /^((\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/i.test(
    ip,
  )

export const validateUrlAddress = (url: string) =>
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i.test(
    url,
  )

export const validateIpAddressOfAsset = (v: string) =>
  validateIpAddress(v) || validateUrlAddress(v)

export const validateMacAddress = (mac: string) =>
  /^(([A-Fa-f0-9]{2}[:]){5}[A-Fa-f0-9]{2}[,]?)+$/g.test(mac)

export const validateFraction = (number: string) =>
  /^(?!.*\d+(?:\.\d+){2})\d*\.?\d*\/?\d*\.?\d*$/g.test(number)

export const validatorIpAddress = (v: string) => {
  const validateValue = validateIpAddressOfAsset(v)
  const invalidValue = (!!v && !validateValue) || textHasWhiteSpace(v)
  if (invalidValue) {
    return Promise.reject(new Error('Invalid Ip Address'))
  }
  return Promise.resolve()
}

export const validatorMacAddress = (v: any) => {
  if (!!v && !validateMacAddress(v)) {
    return Promise.reject(new Error('Invalid Mac Address'))
  }
  return Promise.resolve()
}

export const validatorAppPort = (v: any) => {
  if (!!v && (v < 0 || v > 65535)) {
    return Promise.reject(new Error('Invalid Application Port'))
  }
  return Promise.resolve()
}

export const validatorPortNumber = (v: any, error: string) => {
  if (!!v && (v < 0 || v > 100)) {
    return Promise.reject(new Error(error))
  }
  return Promise.resolve()
}

export const validatorValueNumber = (
  v: any,
  error: string,
  max?: number,
  min = 0,
) => {
  if (!max || !v) {
    return Promise.resolve()
  }
  const newValue = isNaN(v) ? undefined : +v
  const newMax = isNaN(max) ? undefined : +max
  const newMin = isNaN(min) ? 0 : +min
  if (!!newValue && !!newMax && (newValue < newMin || newValue > newMax)) {
    return Promise.reject(new Error(error))
  }
  return Promise.resolve()
}

export const validatorProjectCode = (str: string) => {
  if (str === '' || !str) {
    return false
  }

  // const hasNumber = /\d/.test(str)
  // const hasText = /[A-Z]/.test(str)
  const validLength = str.length <= 5
  const containAnyInvalidChar = /[^A-Z0-9]/.test(str)

  const passtTest = validLength && !containAnyInvalidChar
  return Boolean(passtTest)
}
