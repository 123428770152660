import cx from 'classnames'
import RcSelect, { SelectProps } from 'rc-select'
import { OptionGroupFC } from 'rc-select/lib/OptGroup'
import { OptionFC } from 'rc-select/lib/Option'
import { FC } from 'react'

import { ErrorCircle } from '../../icons'

export type Props = {
  mode?: 'multiple' | 'tags' | 'combobox'
  size?: 'default' | 'large'
  inputIcon?: React.ReactElement
  error?: boolean
  showArrow?: boolean
  popupContainerId?: string
} & Omit<SelectProps, 'mode' | 'prefixCls' | 'menuItemSelectedIcon'>

const sizeClearIcon = {
  default: 'text-[0.75rem]',
  large: 'text-[0.875rem]',
}

const Select: FC<Props> & { Option: OptionFC; OptGroup: OptionGroupFC } = ({
  size = 'large',
  className,
  children,
  error,
  mode = undefined,
  popupContainerId = '',
  ...props
}) => {
  return (
    <RcSelect
      mode={mode}
      prefixCls='select'
      inputIcon={
        <>
          <span className='font-icon-arrow_down text-[1.25rem]' />
          {error && (
            <div className={cx('inline-flex gap-2 absolute items-center')}>
              <span>
                <ErrorCircle className='text-red-900' />
              </span>
            </div>
          )}
        </>
      }
      clearIcon={
        <span className={cx('font-icon-close', sizeClearIcon[size])} />
      }
      animation={props.animation || 'slide-up'}
      menuItemSelectedIcon={<span className='font-icon-check text-[10px]' />}
      dropdownStyle={{ pointerEvents: 'all' }}
      notFoundContent={
        <div className='h-[4.375rem] flex justify-center items-center text-sm opacity-60'>
          No Data
        </div>
      }
      className={cx(
        size,
        className,
        error && 'border-danger',
        'overscroll-y-contain',
      )}
      getPopupContainer={trigger => {
        if (popupContainerId !== '') {
          const parentContainer = document.getElementById(popupContainerId)
          if (parentContainer) {
            return parentContainer
          }
        }
        return trigger.parentNode
      }}
      {...props}
    >
      {children}
    </RcSelect>
  )
}

Select.Option = RcSelect.Option
Select.OptGroup = RcSelect.OptGroup
export { Select }
