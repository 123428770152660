import cx from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { ChangeEvent, FocusEvent, useEffect, useState } from 'react'
import { usePopper } from 'react-popper'

import {
  Collapse,
  Input,
  InputProps,
  Overlay,
  Popover,
  Portal,
} from '../../../components'
import { FolderItem } from '../../../pages/Files/Sidebar/Folders'
import { getFolders, IFolder } from '../../../services/files'
import { FOLDER_ROOT } from '../../../utils/files'
import { filterCategoryHierarchy } from '../../../utils/functions'

interface Props extends Omit<InputProps, 'onSelect'> {
  selected?: IFolder | null
  onSelect?: (item: IFolder) => void
  className?: string
  resourceId?: number | null
  resourceType?: string | null
}

export const FileFoldersSelect = ({
  selected = null,
  onSelect,
  resourceId = null,
  resourceType = null,
  ...props
}: Props) => {
  const [open, setOpen] = useState(false)
  const [focus, setFocus] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [folders, setFolders] = useState<IFolder[]>([])
  const [currentFolders, setCurrentFolders] = useState<IFolder[]>([])
  const [refElement, setRefElement] = useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(refElement, popperElement, {
    placement: 'bottom',
  })
  const [currentSelected, setSelected] = useState<IFolder | null>(null)
  const [rootCurrentFolders, setRootCurrentFolders] = useState<IFolder[]>([])

  const fetchFolders = async () => {
    try {
      const result = await getFolders({
        filterResourceId: resourceId,
        filterResourceType: resourceType,
      })
      if (result?.data?.data) {
        setFolders(result.data.data)
        setCurrentFolders(result.data.data)
      }
    } catch {
      return
    }
  }

  const handleSelect = (item: IFolder | null, rootFolders: IFolder[]) => {
    setKeyword('')
    setSelected(item)
    setRootCurrentFolders([FOLDER_ROOT, ...rootFolders])
    item && onSelect?.(item)
  }

  const handleToggleStatus = (status: boolean) => {
    setOpen(status)
    setFocus(status)
  }

  const handleClearSearch = () => {
    setKeyword('')
    handleSelect(null, [])
    setCurrentFolders(folders)
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value)
    const result = filterCategoryHierarchy(folders, e.target.value, 'name')
    setCurrentFolders(result)
  }

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    props?.onBlur?.(e)
    setFocus(false)
    setKeyword('')
  }

  const renderRootLabel = () => (
    <div
      className='flex items-center gap-2 flex-1 py-1.5'
      onClick={() => {
        handleSelect(FOLDER_ROOT, [])
      }}
    >
      <span
        className={cx(
          'font-icon-folder group-hover:text-primary-900 cursor-pointer',
          currentSelected?.id !== FOLDER_ROOT.id
            ? 'text-black-300'
            : 'text-primary-900',
        )}
      />
      <div className='text-body line-clamp-1'>Root</div>
    </div>
  )

  const renderStrSelected = () =>
    rootCurrentFolders.map((item, index) => (
      <span key={item.id}>
        {`${item.name}${index < rootCurrentFolders.length - 1 ? ' / ' : ''}`}
      </span>
    ))

  const widthPopover = refElement?.offsetWidth

  useEffect(() => {
    fetchFolders()
  }, [])

  return (
    <>
      <div
        ref={setRefElement}
        className='w-full flex-1 border-solid relative overflow-x-hidden'
      >
        {!focus && rootCurrentFolders.length > 0 && (
          <div
            className={cx(
              'flex flex-col justify-center left-3 right-8 top-1/2 -translate-y-1/2 z-10 w-fit max-w-[calc(100%_-_4.5rem)] overflow-hidden absolute h-fit',
              'border border-transparent rounded-lg text-body outline-0 overflow-hidden',
            )}
          >
            <div className='inline-block line-clamp-1 relative gap-1 w-fit capitalize'>
              {renderStrSelected()}
            </div>
          </div>
        )}
        <Input
          onFocus={() => handleToggleStatus(true)}
          onChange={handleSearch}
          onBlur={handleBlur}
          prefixClassName='!left-0 !top-0 md:w-[3.25rem] border-none md:border-solid  md:!bg-separation-100 md:border-separation-800 !bg-transparent'
          value={keyword}
          suffix={
            keyword !== '' || rootCurrentFolders.length > 0 ? (
              <span
                className='font-icon-close cursor-pointer text-13'
                onClick={handleClearSearch}
              ></span>
            ) : (
              <span
                className='font-icon-arrow_down text-[1.25rem] translate-x-2 relative cursor-pointer'
                onClick={() => handleToggleStatus(true)}
              />
            )
          }
          className='capitalize'
          {...props}
        />
      </div>
      <AnimatePresence initial={false}>
        {open && (
          <Portal>
            <Overlay className='!bg-transparent' />
            <div
              ref={setPopperElement}
              className='p-2 z-popover overflow-hidden'
              style={{ ...styles.popper }}
              {...attributes.popper}
            >
              <motion.div
                initial={{ y: '-100%', opacity: 1 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: '-100%', opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Popover.Content
                  onClose={() => handleToggleStatus(false)}
                  className={cx(
                    'min-w-full !p-0 shadow-dropdown max-h-[25rem] h-auto custom-scrollbar overflow-auto',
                  )}
                  style={{
                    width: widthPopover,
                  }}
                >
                  {currentFolders.length > 0 ? (
                    <div className='flex flex-col gap-2 text-body p-2'>
                      <Collapse
                        active={true}
                        panel={renderRootLabel()}
                        panelClassName='items-center group rounded-3xl !p-0 hover:bg-primary-400 cursor-pointer flex-1 w-full gap-0'
                        contentClassName='!p-0'
                        iconWrapperClassName='w-6 h-6'
                        iconClassName='text-[0.6125rem] !text-black-400'
                        onlyClickIcon={true}
                      >
                        <div className='pl-4'>
                          {currentFolders
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(item => (
                              <FolderItem
                                folder={item}
                                onSelect={handleSelect}
                                selectedId={currentSelected?.id || null}
                                level={1}
                                key={item.id}
                                activeCollapse={true}
                                showMenu={false}
                              />
                            ))}
                        </div>
                      </Collapse>
                    </div>
                  ) : (
                    <div className='h-[4.375rem] flex justify-center items-center text-sm opacity-60'>
                      No Data
                    </div>
                  )}
                </Popover.Content>
              </motion.div>
            </div>
          </Portal>
        )}
      </AnimatePresence>
    </>
  )
}
