import cx from 'classnames'

import { Image } from '../../components'
import { IDetailSettings } from '../../services/settings'
import {
  getBgIconSubTypeById,
  getIconDefaultAssetSubType,
} from '../../utils/assets'
import { findSettingById } from '../../utils/settings'

interface Props {
  assetId?: number | null
  assetDetail?: IDetailSettings | null
  showLabel?: boolean
  showOnlyIcon?: boolean
  containerClassName?: string
  labelClassName?: string
  iconClassName?: string
  type?: string
}

export const AssetIconType = ({
  assetId,
  assetDetail,
  showLabel = true,
  showOnlyIcon = false,
  containerClassName,
  labelClassName,
  iconClassName,
  type = 'asset',
}: Props) => {
  let detail: IDetailSettings | null = null

  if (assetDetail) {
    detail = assetDetail
  } else {
    detail = assetId ? findSettingById(type, assetId) : null
  }

  if (!detail) {
    return null
  }

  const metaDetail = detail?.meta?.media || null
  const icon = metaDetail || getIconDefaultAssetSubType(detail, type)
  const iconName = icon.iconName
  const bgIcon = getBgIconSubTypeById(icon.background)

  if (showOnlyIcon) {
    return (
      <div
        className={`font-icon-${iconName} leading-none z-10 text-[0.9375rem] ${iconClassName}`}
      />
    )
  }
  return (
    <div className={cx('flex items-center gap-1.5', containerClassName)}>
      <div className='shrink-0'>
        <div className='relative text-white'>
          <Image src={bgIcon.url} className='w-7 h-7' />
          <div className='z-10 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
            <div
              className={`font-icon-${iconName} leading-none z-10 text-[0.9375rem]`}
            />
          </div>
        </div>
      </div>
      {showLabel && (
        <div className={cx('capitalize', labelClassName)}>{detail.label}</div>
      )}
    </div>
  )
}
