import { OptionValue } from '../../../../utils/form'
import {
  RESOURCE_CONTRACT,
  RESOURCE_PROJECT,
  RESOURCE_QUOTE,
  RESOURCE_SERVICE,
  RESOURCE_TYPE_LABEL,
} from '../../../../utils/resources'
import {
  ContractSelect,
  ProjectSelect,
  QuoteSelect,
  ServicesSelect,
} from '../../../Select'

interface Props {
  label: string
  resource: OptionValue | null
  onSelectResource: (v: OptionValue | null) => void
  account: OptionValue | null
}
export const MoveResources = ({
  label,
  resource,
  onSelectResource,
  account,
}: Props) => {
  const renderResource = () => {
    const defaultProps = {
      fetchOnFirst: false,
      selected: resource,
      onSelect: onSelectResource,
      animation: undefined,
    }
    switch (label) {
      case RESOURCE_TYPE_LABEL[RESOURCE_PROJECT]: {
        return (
          <ProjectSelect
            {...defaultProps}
            placeholder='Select a project'
            filterProject={{
              filterAccount: account,
            }}
          />
        )
      }
      case RESOURCE_TYPE_LABEL[RESOURCE_CONTRACT]: {
        return (
          <ContractSelect
            {...defaultProps}
            placeholder='Select a maintenance contract'
            filterContract={{
              filterAccount: account,
            }}
          />
        )
      }
      case RESOURCE_TYPE_LABEL[RESOURCE_SERVICE]: {
        return (
          <ServicesSelect
            {...defaultProps}
            placeholder='Select a service ticket'
            filterService={{
              filterAccount: account,
            }}
          />
        )
      }
      case RESOURCE_TYPE_LABEL[RESOURCE_QUOTE]: {
        return (
          <QuoteSelect
            {...defaultProps}
            placeholder='Select a Quote'
            filterQuotes={{
              filterAccount: account,
            }}
          />
        )
      }
    }
  }
  return (
    <div>
      <div className='font-medium mb-2'>
        Select a <span className='lowercase'>{label}</span>
      </div>
      <div>{renderResource()}</div>
    </div>
  )
}
