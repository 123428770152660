import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useReducer,
} from 'react'

import { useAsync } from '../../hooks'

interface State {
  billingResult: any
}

type TContext = State & {
  isLoadingFetch: boolean
  dispatch: Dispatch<Partial<State>>
}

const initState: State = {
  billingResult: {},
}

const CustomerPortalContext = createContext<TContext | undefined>(undefined)

const CustomerPortalContextProvider = (props: { children?: ReactNode }) => {
  const { isLoading } = useAsync({
    showNotifOnError: true,
    status: 'pending',
  })
  const [state, dispatch] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    {
      ...initState,
    },
  )

  return (
    <CustomerPortalContext.Provider
      value={{
        ...state,
        isLoadingFetch: isLoading,
        dispatch,
      }}
    >
      {props.children}
    </CustomerPortalContext.Provider>
  )
}

const useCustomerPortalCtx = () => {
  const ctx = useContext(CustomerPortalContext)
  if (ctx === undefined) {
    throw Error('Invalid context call')
  }
  return ctx
}

export { CustomerPortalContextProvider as default, useCustomerPortalCtx }
