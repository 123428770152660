const URL_REGEX =
  /^(?!``)(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$(?!``)/g

const MARKUP_MENTION_REGEX = /(?!``)@\[([^\]]+)]\((\d+)\)(?!``)/g
export const PASSWORD_NOTE_REGEX = /``(.+)``/g

const isValidURL = (u: string) => {
  const elm = document.createElement('input')
  elm.setAttribute('type', 'url')
  elm.value = u
  return elm.validity.valid
}
export const removeMarkupMetions = (str: string) => {
  return str.replace(MARKUP_MENTION_REGEX, '$1')
}

export const renderPasswordNote = (
  text: string,
  isShow = false,
  isRaw = false,
) => {
  if (isShow) {
    return `${text}${
      isRaw
        ? ''
        : "<span class='text-xs font-medium text-primary-900'>(Hide)</span>"
    }`
  }
  return `${Array.from(text).fill('*').join('')}${
    isRaw
      ? ''
      : "<span class='text-xs font-medium text-primary-900'>(Show)</span>"
  }`
}

export const replaceTextPassword = (str: string, isRaw = false) => {
  return str.replace(PASSWORD_NOTE_REGEX, (_, name: string) => {
    return isRaw
      ? renderPasswordNote(name, false, isRaw)
      : `<span class='password cursor-pointer' value='${name}'>${renderPasswordNote(
          name,
        )}</span>`
  })
}

export const replaceTextMentions = (
  str: string,
  linkClassName = '',
  currentId?: number | null,
) => {
  return str.replace(MARKUP_MENTION_REGEX, (_, name, id) => {
    const convertId = +id
    const className = `"font-medium text-primary-900 rounded-[2rem] px-2 cursor-pointer inline-block ${
      convertId === currentId ? 'bg-green-600' : 'bg-primary-400'
    } ${linkClassName}"`
    return ` <a href='/users/${convertId}' class=${className}>${name}</a> `
  })
}
export const replaceTextToLink = (str: string) => {
  const splitByBackSpace = str.split(' ')
  const replaceArray = splitByBackSpace.map(item => {
    return item.replace(URL_REGEX, match => {
      if (isValidURL(match)) {
        return `<a href=${match} target='_blank' class='underline note-link'>${match}</a>`
      }
      return match
    })
  })
  return replaceArray.join(' ')
}

export const replaceNoteBodyByRegex = (
  str: string,
  currentId: number | null,
) => {
  const replaceByLink = replaceTextToLink(str)
  const replaceByMention = replaceTextMentions(replaceByLink, '', currentId)
  const replaceByPassword = replaceTextPassword(replaceByMention, false)
  return replaceByPassword
}
