import { has } from 'lodash'

import { ModalProps } from '../../../../components'
import { ModalDelete } from '../../../../components/Modal/Delete'
import { useAsync } from '../../../../hooks'
import {
  deleteFile,
  deleteFolder,
  IFile,
  IFolder,
} from '../../../../services/files'

interface Props extends Omit<ModalProps, 'children'> {
  onSuccess?: () => void
  onClose: () => void
  data: (IFile | IFolder)[]
}

export const ModalDeleteFile = ({
  onClose,
  onSuccess,
  data,
  ...props
}: Props) => {
  const { execute, isLoading } = useAsync({
    showNotifOnError: true,
  })

  const handleDelete = async () => {
    for (let i = 0; i < data.length; i++) {
      const currentData = data[i]
      const isFile = has(currentData, 'file_name')
      if (isFile) {
        await execute(deleteFile(currentData.id))
      } else {
        await execute(deleteFolder(currentData.id))
      }
    }
    onClose()
    onSuccess?.()
  }

  return (
    <ModalDelete
      title={`Delete ${
        data.length > 1 ? 'these files / folders' : 'this file / folder'
      }`}
      onClose={onClose}
      onCancel={onClose}
      onSubmit={handleDelete}
      loading={isLoading}
      {...props}
    />
  )
}
