import cx from 'classnames'
import { ChangeEvent, memo, useState } from 'react'
import { Link } from 'react-router-dom'

import { Avatar, Button, Checkbox } from '../../components'
import { IWhatsappMessage } from '../../services/whatsApp'
import { formatDateTime } from '../../utils/dateTime'
import { NoteMedias } from '../Notes/Note/Medias'
import { TextAreaNote } from '../TextAreaNote'
import { useUncategorizedCtx } from './Context'

interface Props {
  item: IWhatsappMessage
  canUpdate?: boolean
  canDelete?: boolean
}

export const MessageItem = memo(({ item, canUpdate, canDelete }: Props) => {
  const {
    handleEditUncategorized,
    scrollId,
    selectedIds,
    isSubPage,
    dispatch,
  } = useUncategorizedCtx()
  const [isEdit, setEdit] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleSave = async (v: string) => {
    if (v !== item.message) {
      setLoading(true)
      await handleEditUncategorized(item.id, { message: v })
      setEdit(false)
      setLoading(false)
    }
  }

  const handleToggleCheckBox = (e: ChangeEvent<HTMLInputElement>) => {
    const newList = e.target.checked
      ? [...selectedIds, item.id]
      : selectedIds.filter(s => s !== item.id)
    dispatch({ selectedIds: newList })
  }

  return (
    <div
      className={cx(
        'flex gap-3 items-start bg-white',
        !isSubPage ? 'p-5 shadow-card rounded-lg' : 'pb-4',
      )}
      id={`note-${item.id}`}
    >
      <Checkbox
        checked={selectedIds.includes(item.id)}
        onChange={handleToggleCheckBox}
        wrapperClassName='gap-0 pt-1.5'
        disabled={isEdit}
      />
      <Avatar
        hashId={`user-${item.user_id}`}
        src={item.user.avatar_preview_url}
      />
      <div className='flex-1 max-w-full'>
        {isEdit ? (
          <TextAreaNote
            note={item.message}
            rows={4}
            onCancel={() => setEdit(false)}
            onSave={v => handleSave(v)}
            disabled={loading}
          />
        ) : (
          <>
            <div className='mb-1 flex justify-between items-center'>
              <div className='text-black-400'>
                <span className={'text-blue-900'}>
                  <Link to={`/accounts/${item.account.id}`}>
                    {item.account.full_name}
                  </Link>
                </span>
                <span>&nbsp;| Submitted by: </span>
                <span className={'text-blue-900'}>
                  <Link to={`/users/${item.user.id}`}>{item.user.name}</Link>
                </span>
              </div>
              <Button
                variant='ternary'
                innerClassName='gap-2'
                onClick={() =>
                  dispatch({ moveIds: [item.id], account: item.account })
                }
              >
                <span className='font-icon-share' />
                Move
              </Button>
            </div>
            <div
              className={cx(
                'bg-separation-400 rounded-2xl p-3 mb-1 border-[0.125rem] border-transparent',
                scrollId === item.id && '!border-primary-900',
              )}
            >
              <NoteMedias files={item.files} />
            </div>
            <div className='pl-3 flex gap-2 items-center'>
              {(canDelete || canUpdate) && (
                <>
                  {canUpdate && (
                    <>
                      <div
                        className='text-black-400 font-medium cursor-pointer'
                        onClick={() => setEdit(true)}
                      >
                        Edit
                      </div>
                      <div className='w-1 h-1 rounded-full bg-separation-800' />
                    </>
                  )}
                  {canDelete && (
                    <>
                      <div
                        className='text-black-400 font-medium cursor-pointer'
                        onClick={() => dispatch({ deleteIds: [item.id] })}
                      >
                        Delete
                      </div>
                      <div className='w-1 h-1 rounded-full bg-separation-800' />
                    </>
                  )}
                </>
              )}
              <div className='text-black-400'>
                {formatDateTime(item.created_at)}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
})
