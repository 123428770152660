import { AnimatePresence } from 'framer-motion'
import { useState } from 'react'

import { IFile } from '../../../../services/files'
import { getFileType } from '../../../../utils/files'
import { ModalDetailMedia } from '../../../Modals/Files/Detail'
import { NoteAudio } from './Audio'
import { NoteImage } from './Image'
import { NoteVideo } from './Video'

interface Props {
  files: IFile[]
}
export const NoteMedias = ({ files }: Props) => {
  const [openFile, setOpenFile] = useState<IFile | null>(null)

  const sortMedias = (list: IFile[]) => {
    const listAudios: IFile[] = []
    const listVideos: IFile[] = []
    const listDefault: IFile[] = []
    list.forEach(item => {
      const fileType = getFileType(item.mime_type)
      if (fileType === 'audio') {
        listAudios.push(item)
      } else if (fileType === 'video') {
        listVideos.push(item)
      } else {
        listDefault.push(item)
      }
    })
    return [...listDefault, ...listAudios, ...listVideos]
  }
  const renderItem = (file: IFile) => {
    const fileType = getFileType(file.mime_type)
    switch (fileType) {
      case 'audio': {
        return <NoteAudio file={file} key={file.id} onOpenFile={setOpenFile} />
      }
      case 'video': {
        return <NoteVideo file={file} key={file.id} onOpenFile={setOpenFile} />
      }
      default: {
        return <NoteImage file={file} key={file.id} onOpenFile={setOpenFile} />
      }
    }
  }
  if (files.length === 0) {
    return null
  }

  const listSort = sortMedias(files)
  return (
    <>
      <div className='grid grid-cols-[repeat(auto-fill,minmax(6.25rem,1fr))] gap-2 mt-2'>
        {listSort.map(item => renderItem(item))}
      </div>
      <AnimatePresence initial={false}>
        {openFile && (
          <ModalDetailMedia
            selectedFile={openFile}
            onClose={() => setOpenFile(null)}
          />
        )}
      </AnimatePresence>
    </>
  )
}
