import { useState } from 'react'

import { Button, Modal, ModalProps } from '../../../../components'
import { useAsync } from '../../../../hooks'
import { IAccount } from '../../../../services/accounts'
import { moveWhatsappMessage } from '../../../../services/whatsApp'
import { OptionValue } from '../../../../utils/form'
import {
  mapResourceToOptionValue,
  RESOURCE_ACCOUNT,
  RESOURCE_CONTRACT,
  RESOURCE_PROJECT,
  RESOURCE_QUOTE,
  RESOURCE_SERVICE,
  RESOURCE_TYPE_LABEL,
} from '../../../../utils/resources'
import { AccountSelect } from '../../../Select'
import { TabsViewType } from '../../../Tabs/TabsViewType'
import { MoveResources } from './Resources'

interface Props extends Omit<ModalProps, 'children'> {
  onSuccess?: () => void
  onClose: () => void
  moveIds: number[]
  account?: IAccount | null
}

const LIST_TAB = [
  RESOURCE_TYPE_LABEL[RESOURCE_PROJECT],
  RESOURCE_TYPE_LABEL[RESOURCE_CONTRACT],
  RESOURCE_TYPE_LABEL[RESOURCE_SERVICE],
  RESOURCE_TYPE_LABEL[RESOURCE_QUOTE],
]

export const ModalMoveUncategorized = ({
  onClose,
  onSuccess,
  moveIds,
  account = null,
  ...props
}: Props) => {
  const defaultAccount = mapResourceToOptionValue(
    account,
    RESOURCE_ACCOUNT,
  ) as OptionValue
  const [selectedAccount, setSelectedAccount] =
    useState<OptionValue>(defaultAccount)
  const [tab, setTab] = useState(LIST_TAB[0])
  const [selectedResource, setSelectedResource] = useState<OptionValue | null>(
    null,
  )

  const { execute, isLoading } = useAsync({ showNotifOnError: true })

  const handleChangeTab = (selected: string) => {
    setSelectedResource(null)
    setTab(selected)
  }

  const getResourceTypeByLabel = (label: string) => {
    const detailResource = Object.entries(RESOURCE_TYPE_LABEL).find(
      ([key, value]) => value === label,
    )
    return detailResource?.[0]
  }
  const handleSave = async () => {
    let resource_id = selectedAccount.value
    let resource_type = RESOURCE_ACCOUNT
    if (selectedResource?.value) {
      resource_type = getResourceTypeByLabel(tab) || RESOURCE_ACCOUNT
      resource_id = selectedResource.value
    }
    if (resource_id && resource_type) {
      await execute(
        moveWhatsappMessage({
          whatsapp_chat_message_ids: moveIds,
          resource_type,
          resource_id,
        }),
      )
    }
    onClose()
    onSuccess?.()
  }

  return (
    <Modal {...props} className='max-w max-w-lg w-full p-4' onClose={onClose}>
      <div className='relative text-body'>
        <span
          className='cursor-pointer font-icon-close text-black-400 absolute -right-3 -top-2 w-10 h-10 flex justify-center items-center'
          onClick={onClose}
        />
        <div className='text-base font-medium text-black-800 mb-4'>
          Move items
        </div>
        <div className='bg-blue-400 p-3 rounded-lg flex gap-2 text-body mb-8'>
          <span className='font-icon-info mt-1' />
          <div>
            This item will be moved to the “Note” section of the destination
            resource
          </div>
        </div>
        <div className='mb-8'>
          <div className='mb-2 font-medium'>Account</div>
          <AccountSelect
            selected={selectedAccount}
            className='w-full'
            size='large'
            allowClear={false}
            onSelect={v => {
              setSelectedAccount(v as OptionValue)
              setSelectedResource(null)
            }}
            fetchOnFirst={false}
            dropdownMatchSelectWidth={false}
          />
        </div>
        <div className='mb-8'>
          <div className='mb-2 font-medium'>Destination</div>
          <div className='max-w-fit'>
            <TabsViewType
              data={LIST_TAB}
              currentSelected={tab}
              onSelect={handleChangeTab}
            />
          </div>
        </div>
        <MoveResources
          label={tab}
          resource={selectedResource}
          onSelectResource={setSelectedResource}
          account={selectedAccount}
        />
        <div className='flex justify-end gap-2 pt-4 mt-6'>
          <Button variant='ternary' onClick={onClose} asLink>
            Cancel
          </Button>
          <Button
            variant='primary'
            innerClassName='px-10 gap-2'
            onClick={handleSave}
            disabled={isLoading || !selectedAccount?.value}
          >
            Done
          </Button>
        </div>
      </div>
    </Modal>
  )
}
