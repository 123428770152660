import { ReactNode } from 'react'

import { Button } from '../Button'
import { Modal, ModalProps } from '.'

interface Props extends Omit<ModalProps, 'children'> {
  onClose: () => void
  onCancel?: () => void
  onSubmit?: () => void
  loading?: boolean
  title?: ReactNode
  content?: ReactNode
  cancelText?: ReactNode
  submitText?: ReactNode
  modalIcon?: ReactNode
  children?: ReactNode
}

export const ModalDelete = ({
  onClose,
  onCancel,
  onSubmit,
  loading,
  title,
  content,
  submitText,
  cancelText,
  modalIcon,
  children,
  ...props
}: Props) => {
  const handleClose = () => {
    if (loading) {
      return
    }
    onClose()
  }
  const handleCancel = () => {
    handleClose()
    onCancel?.()
  }

  return (
    <Modal className='max-w-[27rem] w-full' {...props} onClose={handleClose}>
      <div className='flex gap-3 relative'>
        <span
          className='cursor-pointer font-icon-close text-black-400 absolute -right-2 -top-2 z-10 w-10 h-10 flex justify-center items-center'
          onClick={handleClose}
        />
        {modalIcon || (
          <div className='w-10 h-10 bg-red-200 rounded-full flex items-center justify-center'>
            <span className='font-icon-trash text-red-800 text-[1.25rem]' />
          </div>
        )}
        <div className='flex-1'>
          <div className='text-base font-medium text-black-800 mb-3'>
            {title}
          </div>
          <div className='text-body text-black-400 mb-6'>
            {content || children || 'Action can’t be undone, are you sure?'}
          </div>
          <div className='flex justify-end gap-2'>
            <Button
              variant='ternary'
              onClick={handleCancel}
              disabled={loading}
              asLink
            >
              {cancelText || 'Cancel'}
            </Button>
            <Button
              variant='primary'
              onClick={onSubmit}
              className='bg-red-900'
              disabled={loading}
            >
              {submitText || 'Delete'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
