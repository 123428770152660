import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useReducer,
} from 'react'

import { useAsync } from '../../hooks'
import { IAccount } from '../../services/accounts'
import {
  editWhatsappMessage,
  getWhatsappMessages,
  IWhatsappMessage,
  IWhatsappMessagePayload,
} from '../../services/whatsApp'
import { OptionValue } from '../../utils/form'
import { updateIndex } from '../../utils/functions'

interface State {
  listUncategorized: IWhatsappMessage[]
  resultUncategorized: IWhatsappMessage[]
  currentPage: number
  lastPage: number
  lastPageIsFetched: boolean
  account?: IAccount | null
  keyword: string
  loading: boolean
  fetching: boolean
  scrollId: number | null
  selectedIds: number[]
  moveIds: number[]
  deleteIds: number[]
  isSubPage: boolean
  filterAccount: OptionValue | null
  filterFileFormat: string | null
}

type TContext = State & {
  dispatch: Dispatch<Partial<State>>
  handleInitUncategorized: () => Promise<void>
  fetchNextPageData: () => Promise<void>
  handleEditUncategorized: (
    id: number,
    data: Partial<IWhatsappMessagePayload>,
  ) => Promise<void>
  handleDeleteUncategorized: (ids: number[]) => void
}

const UncategorizedContext = createContext<TContext | undefined>(undefined)

const UncategorizedContextProvider = (props: {
  children?: ReactNode
  account?: IAccount | null
  fileType?: string | null
  hideFilter?: boolean
  isSubPage?: boolean
}) => {
  const [state, dispatch] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    {
      listUncategorized: [],
      resultUncategorized: [],
      currentPage: 1,
      lastPage: 1,
      lastPageIsFetched: false,
      keyword: '',
      loading: false,
      fetching: true,
      scrollId: null,
      account: props?.account || null,
      selectedIds: [],
      moveIds: [],
      deleteIds: [],
      isSubPage: Boolean(props.isSubPage),
      filterAccount: null,
      filterFileFormat: null,
    },
  )
  const listAsync = useAsync({ showNotifOnError: true })
  const editAsync = useAsync({ showNotifOnError: true })

  const handleEditUncategorized = async (
    id: number,
    data: Partial<IWhatsappMessagePayload>,
  ) => {
    const result = await editAsync.execute(editWhatsappMessage(id, data))
    const newList = updateIndex(state.listUncategorized, id, result.data.data)
    dispatch({
      listUncategorized: newList,
    })
  }

  const handleDeleteUncategorized = async (ids: number[]) => {
    const newListNotes = [...state.listUncategorized].filter(
      item => !ids.includes(item.id),
    )
    const newSelectedIds = [...state.selectedIds].filter(
      item => !ids.includes(item),
    )
    dispatch({
      selectedIds: newSelectedIds,
      listUncategorized: newListNotes,
    })
  }

  const fetchNextPageData = async (): Promise<void> => {
    if (state.currentPage <= state.lastPage) {
      const result = await listAsync.execute(
        getWhatsappMessages({
          filterAccountId: state.account?.id,
          filterFileType: state.filterFileFormat,
          currentPage: state.currentPage,
        }),
      )
      if (result?.data?.meta) {
        const listData = result.data.data || []
        dispatch({
          currentPage:
            state.currentPage < state.lastPage
              ? state.currentPage + 1
              : state.currentPage,
          lastPageIsFetched: state.currentPage === state.lastPage,
          listUncategorized: [...state.listUncategorized, ...listData],
        })
      }
    }
  }

  const handleInitUncategorized = async () => {
    const listData = await listAsync.execute(
      getWhatsappMessages({
        filterAccountId: state.account?.id,
        filterFileType: state.filterFileFormat,
        currentPage: state.currentPage,
      }),
    )
    dispatch({
      listUncategorized: listData.data?.data,
      fetching: false,
      currentPage: state.currentPage + 1,
      lastPage: listData?.data?.meta?.last_page,
    })
  }

  return (
    <UncategorizedContext.Provider
      value={{
        ...state,
        dispatch,
        handleInitUncategorized,
        fetchNextPageData,
        handleEditUncategorized,
        handleDeleteUncategorized,
      }}
    >
      {props.children}
    </UncategorizedContext.Provider>
  )
}

const useUncategorizedCtx = () => {
  const ctx = useContext(UncategorizedContext)
  if (ctx === undefined) {
    throw Error('Invalid context call')
  }
  return ctx
}

export { UncategorizedContextProvider as default, useUncategorizedCtx }
