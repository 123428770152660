import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useReducer,
} from 'react'
import { useParams } from 'react-router-dom'
import shallow from 'zustand/shallow'

import { useAsync } from '../../hooks'
import { AuthUser } from '../../services/auth'
import { createFile, IFile } from '../../services/files'
import { createNote, editNote, getNotes, INote } from '../../services/notes'
import useStore, { Store } from '../../store'
import { updateIndex } from '../../utils/functions'
import {
  RESOURCE_ACCOUNT,
  RESOURCE_NOTE,
  RESOURCE_TYPE_LABEL,
} from '../../utils/resources'

export const BODY_ONLY_IMG = '<div />'
interface State {
  currentUser: AuthUser | null
  listNotes: INote[]
  resultNotes: INote[]
  pinnedNote: INote | null
  body: string
  resourceId: number | null
  resourceType: string
  keyword: string
  loading: boolean
  scrollId: number | null
  detailNoteId: number | null
  showFilter: boolean
  listAttachments: File[]
  listNameAttachments: Partial<IFile>[]
  fetching: boolean
  filterByAccount: number | null
  progressUpload: Record<number, string>
}

type TContext = State & {
  dispatch: Dispatch<Partial<State>>
  getListFilters: (type: string) => Array<{ value: string; label: string }>
  handleDeleteNote: (id: number) => void
  handleEditNote: (id: number, text: string) => Promise<void>
  handleAddNote: (content: string) => Promise<void>
  handleInitNotes: () => Promise<void>
  handleTogglePin: (note: INote, status?: boolean) => Promise<void>
}

const mapStore = (store: Store) => ({
  auth: store.auth.currentUser,
})

const initState: State = {
  listNotes: [],
  resultNotes: [],
  pinnedNote: null,
  body: '',
  resourceId: 0,
  resourceType: RESOURCE_ACCOUNT,
  keyword: '',
  loading: false,
  fetching: true,
  scrollId: null,
  detailNoteId: null,
  showFilter: false,
  listAttachments: [],
  listNameAttachments: [],
  progressUpload: {},
  currentUser: null,
  filterByAccount: null,
}

const NotesContext = createContext<TContext | undefined>(undefined)

const NotesContextProvider = (props: {
  children?: ReactNode
  notes?: INote[]
  resourceId: number
  resourceType: string
  showFilter?: boolean
}) => {
  const params = useParams()
  const { auth } = useStore(mapStore, shallow)
  const [state, dispatch] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    {
      ...initState,
      resourceId: props?.resourceId || null,
      resourceType: props?.resourceType || RESOURCE_ACCOUNT,
      showFilter: Boolean(props?.showFilter),
      currentUser: auth,
    },
  )
  const addAsync = useAsync({ showNotifOnError: true })
  const editAsync = useAsync({ showNotifOnError: true })
  const listAsync = useAsync({ showNotifOnError: true })
  const uploadAsync = useAsync({ showNotifOnError: true })

  const getListFilters = (type: string) => {
    return [
      { value: 'all', label: 'All' },
      {
        value: 'this',
        label: `Relate to this ${RESOURCE_TYPE_LABEL[type]}`,
      },
    ]
  }

  const handleDeleteNote = (id: number) => {
    const newListNotes = [...state.listNotes].filter(item => item.id !== id)
    dispatch({
      listNotes: newListNotes,
    })
  }

  const handleEditNote = async (id: number, txt: string) => {
    const result = await editAsync.execute(editNote(id, { body: txt }))
    const newList = updateIndex(state.listNotes, id, result.data.data)
    dispatch({
      listNotes: newList,
    })
  }

  const handleUpload = async (
    file: File,
    index: number,
    noteId: number,
  ): Promise<IFile | null> => {
    const { progressUpload, listNameAttachments } = state
    try {
      if (progressUpload[index] !== 'uploading') {
        dispatch({
          progressUpload: {
            ...progressUpload,
            [index]: 'uploading',
          },
        })
      }
      const newFileName = listNameAttachments.find(item => item.id === index)
      const resultUpload = await uploadAsync.execute(
        createFile({
          file,
          resource_id: noteId,
          resource_type: RESOURCE_NOTE,
          name: newFileName?.name || null,
        }),
      )
      if (resultUpload.data.data) {
        dispatch({
          progressUpload: {
            ...progressUpload,
            [index]: 'uploaded',
          },
        })
        return resultUpload.data.data
      } else {
        return null
      }
    } catch (error) {
      dispatch({
        progressUpload: {
          ...progressUpload,
          [index]: 'failed',
        },
      })
      return null
    }
  }

  const handleAddNote = async (content: string) => {
    const { resourceId, resourceType } = state
    if (!resourceId) {
      return
    }
    dispatch({
      loading: true,
    })
    const result = await addAsync.execute(
      createNote({
        resource_id: resourceId,
        resource_type: resourceType,
        body: content,
      }),
    )
    const { listAttachments } = state
    const listUploadedFiles: IFile[] = []
    if (listAttachments.length > 0 && result.data.data) {
      for (let index = 0; index < listAttachments.length; index++) {
        const resultUpload = await handleUpload(
          listAttachments[index],
          index,
          result.data.data.id,
        )
        if (resultUpload) {
          listUploadedFiles.push(resultUpload)
        }
      }
    }

    const newNote: INote = {
      ...result.data.data,
      user: {
        name: auth?.name,
        avatar_preview_url: auth?.avatar_preview_url || null,
      },
      files: listUploadedFiles,
    }
    dispatch({
      body: '',
      listNotes: [...state.listNotes, newNote],
      loading: false,
      listAttachments: [],
      listNameAttachments: [],
    })
  }

  const handleTogglePin = async (note: INote, isPin = true) => {
    const { pinnedNote } = state
    const result = await editAsync.execute(
      editNote(note.id, { meta: [{ pinned: isPin }], body: note.body }),
    )
    if (isPin && pinnedNote) {
      await editAsync.execute(
        editNote(pinnedNote.id, {
          meta: [{ pinned: false }],
          body: pinnedNote.body,
        }),
      )
    }
    const newList = updateIndex(state.listNotes, note.id, result.data.data)
    dispatch({
      listNotes: newList,
      pinnedNote: isPin
        ? {
            ...note,
            meta: [{ pinned: isPin }],
          }
        : null,
    })
  }

  const loopInitNotes = async (
    data: INote[] = [],
    nextPage: number = 1,
  ): Promise<INote[]> => {
    const { filterByAccount, resourceId, resourceType } = state
    let listData = [...data]
    const result = await listAsync.execute(
      getNotes({
        resource: filterByAccount ? null : `${resourceType},${resourceId}`,
        account_id:
          filterByAccount && resourceType === RESOURCE_ACCOUNT
            ? resourceId
            : null,
        currentPage: nextPage,
      }),
    )
    if (result?.data?.meta) {
      const currentPage = result.data.meta?.current_page
      const lastPage = result.data.meta?.last_page
      const currentData = result.data?.data ? result.data?.data : []
      listData = [...listData, ...currentData]
      if (currentPage < lastPage) {
        return loopInitNotes(listData, nextPage + 1)
      }
    }
    return listData
  }

  const handleInitNotes = async () => {
    try {
      const noteIdOnUrl = params?.noteId || null
      dispatch({
        fetching: true,
      })
      const listData = await loopInitNotes()
      const pinnedNote = listData.find(item => Boolean(item.meta?.[0]?.pinned))
      dispatch({
        listNotes: listData,
        pinnedNote,
        detailNoteId: noteIdOnUrl ? +noteIdOnUrl : null,
      })
    } finally {
      dispatch({
        fetching: false,
      })
    }
  }

  return (
    <NotesContext.Provider
      value={{
        ...state,
        dispatch,
        getListFilters,
        handleDeleteNote,
        handleEditNote,
        handleAddNote,
        handleInitNotes,
        handleTogglePin,
      }}
    >
      {props.children}
    </NotesContext.Provider>
  )
}

const useNotesCtx = () => {
  const ctx = useContext(NotesContext)
  if (ctx === undefined) {
    throw Error('Invalid context call')
  }
  return ctx
}

export { NotesContextProvider as default, useNotesCtx }
