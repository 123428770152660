import { KeyboardEventHandler, useState } from 'react'

import { TextArea, TextAreaProps } from '../components'

interface Props extends Omit<TextAreaProps, 'ref' | 'onChange'> {
  note: string
  onSave?: (note: string) => void
  onCancel?: () => void
}
export const TextAreaNote = ({ note, onSave, onCancel, ...props }: Props) => {
  const [value, setValue] = useState(note)

  const handleSaveNote = () => {
    onSave?.(value)
  }

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = e => {
    e.key === 'Escape' && onCancel?.()
    e.key === 'Enter' && !e.shiftKey && handleSaveNote()
  }

  return (
    <>
      <TextArea
        defaultValue={note}
        className='w-full !text-xs !p-2 rounded-md border border-separation-900 focus:border-primary-900'
        onKeyDown={handleKeyDown}
        onChange={e => setValue(e.target.value)}
        {...props}
      />
      <div className='text-xs'>
        Press Enter to{' '}
        <span
          className='font-medium cursor-pointer hover:underline text-primary-900'
          onClick={handleSaveNote}
        >
          Save
        </span>
        , Esc to{' '}
        <span
          className='font-medium cursor-pointer hover:underline text-primary-900'
          onClick={onCancel}
        >
          Cancel
        </span>
      </div>
    </>
  )
}
