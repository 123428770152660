import ImgThumbnail from '../../../../assets/images/img_thumbnail.svg'
import { Image } from '../../../../components'
import { IFile } from '../../../../services/files'

interface Props {
  file: IFile
  onOpenFile: (file: IFile) => void
}
export const NoteImage = ({ file, onOpenFile }: Props) => {
  const url = file.preview_url || file.url || ImgThumbnail
  return (
    <>
      <div
        key={file.id}
        className='w-full h-full rounded-lg cursor-pointer'
        onClick={() => onOpenFile(file)}
      >
        <Image src={url} className='w-full h-full max-h-[6.5rem] rounded-lg' />
      </div>
    </>
  )
}
