import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion'
import { useEffect } from 'react'

import { Modal, ModalProps } from '../../../../components'
import { IFilter } from '../../../../services/client'
import { IFile } from '../../../../services/files'
import { isMobile } from '../../../../utils/functions'
import { ModalDeleteFile } from '../Delete'
import { ModalEditTag } from '../EditTag'
import { ModalRenameFile } from '../Rename'
import { ModalFileUserAccess } from '../UserAccess'
import FileDetailContextProvider, { useFileDetailCtx } from './Context'
import { GalleryMain } from './GalleryMain'
import { GalleryHeader } from './Header'
import { MediaInfo } from './Info'
import { MediaNote } from './Note'

interface Props extends Omit<ModalProps, 'children'> {
  onSuccess?: (file?: IFile) => void
  selectedFile: IFile
  listRelatedFiles?: IFile[]
  currentPage?: number
  lastPage?: number
  filterFiles?: IFilter | null
  onUpdateFile?: (data: IFile) => void
}

interface ContainerProps extends Omit<ModalProps, 'children'> {
  onSuccess?: (file?: IFile) => void
}
export const ModalDetailMedia = ({
  selectedFile,
  listRelatedFiles,
  filterFiles,
  lastPage,
  currentPage,
  onUpdateFile,
  ...props
}: Props) => {
  return (
    <FileDetailContextProvider
      selectedFile={selectedFile}
      listRelatedFiles={listRelatedFiles || []}
      filterFiles={filterFiles}
      lastPage={lastPage || 1}
      currentPage={currentPage || 1}
      fnUpdateFile={onUpdateFile}
    >
      <ContainerDetailMedia {...props} />
    </FileDetailContextProvider>
  )
}

const ContainerDetailMedia = (props: ContainerProps) => {
  const isOnMobile = isMobile() || window.innerWidth < 768

  const {
    file,
    openEditTag,
    onUpdateFile,
    toggleInfo,
    toggleNote,
    openDeleteFile,
    openManageAccess,
    openRenameFile,
    dispatch,
  } = useFileDetailCtx()

  const motionProps: HTMLMotionProps<'div'> = isOnMobile
    ? {
        initial: { y: '100%', width: '100%', bottom: 0, zIndex: 10 },
        animate: { y: 0, position: 'fixed' },
        exit: { y: '100%' },
        transition: { duration: 0.3 },
      }
    : {
        initial: { x: '100%' },
        animate: { x: 0 },
        exit: { x: '100%' },
        transition: { duration: 0.3 },
      }
  const handleKeydownModal = (e: KeyboardEvent) => {
    const { key } = e
    if (key === 'Escape') {
      props.onClose()
      return
    }
    if (key === 'ArrowLeft') {
      const buttonEl = document.getElementById('media-slider-prev')
      if (buttonEl) {
        buttonEl.click()
      }
    } else if (key === 'ArrowRight') {
      const buttonEl = document.getElementById('media-slider-next')
      if (buttonEl) {
        buttonEl.click()
      }
    }
  }
  useEffect(() => {
    window.addEventListener('keydown', handleKeydownModal)
    return () => {
      window.removeEventListener('keydown', handleKeydownModal)
    }
  }, [])
  return (
    <>
      <Modal
        {...props}
        onClose={props.onClose}
        className='w-screen bg-transparent h-full !p-0'
        wrapperClassName='p-0'
      >
        <div className='flex h-full'>
          <div className='h-full flex-1 flex-col pt-6 flex relative'>
            <div
              className='absolute inset-0 cursor-pointer z-1'
              onClick={props.onClose}
            />
            <GalleryHeader onClose={props.onClose} />
            <GalleryMain />
          </div>
          <AnimatePresence>
            {toggleInfo && (
              <motion.div {...motionProps}>
                <div className='z-[10] md:max-w-[22.5rem] w-full bg-white left-0 right-0 md:relative bottom-0 md:h-full'>
                  <MediaInfo />
                </div>
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {toggleNote && (
              <motion.div {...motionProps}>
                <div className='z-[10] md:w-[25rem] w-full bg-white left-0 right-0 md:relative bottom-0 md:h-full'>
                  <MediaNote />
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </Modal>
      <AnimatePresence>
        {openEditTag && (
          <ModalEditTag
            file={file}
            onClose={() => dispatch({ openEditTag: false })}
            onSuccess={onUpdateFile}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {openRenameFile && (
          <ModalRenameFile
            data={file}
            onClose={() => dispatch({ openRenameFile: false })}
            onSuccess={onUpdateFile}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {openDeleteFile && (
          <ModalDeleteFile
            data={[file]}
            onClose={() => dispatch({ openDeleteFile: false })}
            onSuccess={() => {
              onUpdateFile(file)
              props.onClose()
            }}
          />
        )}
      </AnimatePresence>
      <AnimatePresence initial={false}>
        {openManageAccess && (
          <ModalFileUserAccess
            file={file}
            onClose={() => dispatch({ openManageAccess: false })}
            onSuccess={onUpdateFile}
          />
        )}
      </AnimatePresence>
    </>
  )
}
