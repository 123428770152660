import { OptionValue } from '../utils/form'
import { generateQueryOfEndPoint } from '../utils/functions'
import { IAccount } from './accounts'
import client, {
  IFilter,
  PAGE_SIZE,
  PAGE_SIZE_RECENT,
  ResponseMeta,
} from './client'
import { IContact } from './contacts'
import { IFile } from './files'
import { IInvoice } from './invoices'
import { INote } from './notes'
import { IPermit } from './permits'
import { IQuote } from './quotes'
import { IDetailSettings } from './settings'
import { ITag } from './tags'
import { IUser } from './users'

export interface IProjectKpi {
  actual_net: number | null
  expenses: number | null
  projected_net: number | null
  timesheets_cost: number | null
  total_expenses: number | null
}
export interface ResponseProjectMeta extends ResponseMeta {
  recently_updated_count: number
  due_date_soon_count: number
  overdue_count: number
  blocked_count: number
  favorited_count: number
}

export interface IResponseProjects {
  data: IProject[]
  meta: ResponseProjectMeta
}

export interface IResponseProjectDetail {
  data: IProject
  meta: IProjectKpi
}

export interface IProjectOwner {
  id: number
  account_id: number
  avatar?: string | null
  avatar_url?: string | null
  avatar_preview_url?: string | null
  email: string
  email_verified_at?: string | null
  first_name: string
  last_name: string
  name: string
  phone?: string | null
}
export interface IProject {
  id: number
  name: string
  description?: string | null
  owner_id: number
  code: string | null
  est_value?: number | null
  estimated_start_date?: Date | string | null
  actual_start_date?: Date | string | null
  due_date?: Date | string | null
  has_passed_due_date?: boolean
  created_at: Date | string
  updated_at?: Date | string | null
  address: string
  status: string
  status_id: number
  total_square_units?: number | null
  max_ceiling_height?: number | null
  lift_required?: number | null
  lift_type?: string | null
  is_favorited: boolean
  favorites_count: number
  assignees?: IUser[] | null
  is_watched: boolean
  watchers_count: number
  watchers?: IUser[] | null
  owner?: IContact | null
  contact?: IContact | null
  contacts?: IContact[] | null
  account_id: number
  account: IAccount
  allNotes?: INote[] | null
  notes?: INote[] | null
  allFiles?: IFile[] | null
  quotes?: IQuote[] | null
  invoices?: IInvoice[] | null
  inbound_email_address?: string | null
  tags?: ITag[] | null
  systemTypes?: IDetailSettings[] | null
  vendorAccount?: IAccount | null
  vendor_account_id?: number | null
  permits: IPermit[] | null
}

export interface IProjectPayload {
  account_id: number
  owner_id: number
  name: string
  estimated_start_date?: Date | string | null
  actual_start_date?: Date | string | null
  due_date?: Date | string | null
  est_value?: number | null
  status: string
  status_id: number
  address: string
  total_square_units?: number | null
  max_ceiling_height?: number | null
  lift_required?: string | null
  watchers?: number[] | null
  assignees?: number[] | null
  contact?: number | null
  contacts?: number[] | null
  quote_ids?: number[] | null
  description?: string | null
  lift_type?: string | null
  tags?: number[] | null
  inbound_email_address?: string | null
  code?: string | null
  system_type_ids?: number[] | null
  vendor_account_id?: number | null
}

export interface IFilterProjects extends IFilter {
  filterAccount?: OptionValue | null
  filterStatus?: OptionValue | null
  filterKpi?: boolean
  filterRecentUpdated?: boolean
  filterDueDateSoon?: boolean
  filterOverDue?: boolean
  filterIsFavorited?: boolean
  filterStatusDirection?: 'include' | 'exclude' | null
  filterSpecialStatus?: string | null
  include?: string | null
}

export const getProjects = async ({
  search = '',
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
  filterAccount = null,
  filterStatus = null,
  filterRecentUpdated = false,
  filterDueDateSoon = false,
  filterOverDue = false,
  filterIsFavorited = false,
  filterKpi = false,
  filterStatusDirection = 'include',
  filterSpecialStatus = null,
  include = null,
}: IFilterProjects) => {
  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortBy}`
  const endPoint = generateQueryOfEndPoint('/api/projects', {
    sort,
    'filter[search]': search,
    limit: pageSize,
    page: currentPage,
    'filter[account_id]': filterAccount?.value || null,
    [`filter[${
      filterStatusDirection === 'exclude' && filterSpecialStatus ? '-' : ''
    }status]`]: filterSpecialStatus || filterStatus?.label || null,
    'filter[recently_updated]': filterRecentUpdated ? 1 : null,
    'filter[due_date_soon]': filterDueDateSoon ? 1 : null,
    'filter[overdue]': filterOverDue ? 1 : null,
    'filter[is_favorited]': filterIsFavorited ? 1 : null,
    kpi: filterKpi ? 1 : 0,
  })
  return client.get<IResponseProjects>(
    `${endPoint}include=${
      include ||
      'account,quotes,invoices,inboundEmailAddress,favorites,systemTypes'
    }`,
  )
}

export const getRecentProjects = async (props: IFilterProjects) => {
  return getProjects({
    currentPage: 1,
    ...props,
    sortBy: 'recent',
    sortDirection: 'asc',
    pageSize: PAGE_SIZE_RECENT,
  })
}

export const createProject = async (payload: IProjectPayload) => {
  return client.post('/api/projects', payload)
}

export const editProject = (
  projectId: number,
  payload: Partial<IProjectPayload>,
) => {
  return client.put<{ data: IProject }>(`/api/projects/${projectId}`, payload)
}
export const deleteProject = async (projectId: number) => {
  return client.delete(`/api/projects/${projectId}`)
}

export const detailProjectShort = async (projectId: number) => {
  return client.get<IResponseProjectDetail>(
    `api/projects/${projectId}?include=watchers,owner,assignees,favorites,contact,contacts,account,notes,quotes,inboundEmailAddress,tags,systemTypes,vendorAccount&kpi=1`,
  )
}

export const detailProject = async (projectId: number) => {
  return client.get<{ data: IProject }>(
    `api/projects/${projectId}?include=contact,contacts,account,allFiles,quotes,owner,tags,inboundEmailAddress,systemTypes,vendorAccount`,
  )
}

export const getProjectKpi = async (projectId: number) => {
  return client.get<{ data: any }>(`/api/projects/${projectId}/kpi`)
}

export const postValidateProjectCode = (code: string) => {
  return client.post('/api/projects?validate=1', {
    code,
  })
}

export const putValidateProjectCode = (code: string, projectId: number) => {
  return client.put(`/api/projects/${projectId}?validate=1`, {
    code,
  })
}
