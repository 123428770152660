import { OptionValue } from '../utils/form'
import { generateQueryOfEndPoint } from '../utils/functions'
import { IAccount } from './accounts'
import { IAsset } from './assets'
import client, { IFilter, PAGE_SIZE, PAGE_SIZE_RECENT } from './client'
import { IFile } from './files'
import { INote } from './notes'
import { IUserRole } from './roles'
import { IReponseItemSetting } from './settings'
import { ITag } from './tags'

export enum USER_ROLE {
  TECHNICIAN = 'techinician',
  CUSTOMER = 'customer',
  ADMIN = 'admin',
}

export interface IFetchResponseWatchers {
  data: {
    data: any
  }
}
export interface IUserDetail {
  dob?: string | null
  fax?: string | null
  driver_license_number?: string | null
  driver_license_state?: string | null
  address?: string | null
  city?: string | null
  state?: string | null
  country?: string | null
  zip?: string | null
}

export interface IUserNotificationType {
  in_app: boolean
  sms: boolean
  email: boolean
}
export interface IUserNotification {
  watch_resource: IUserNotificationType
  assigned_resource: IUserNotificationType
  payment: IUserNotificationType
}
export interface IUser {
  id: number
  account_id?: number | null
  account?: IAccount | null
  avatar?: string | null
  avatar_url?: string | null
  avatar_preview_url?: string | null
  name: string
  first_name: string
  last_name: string
  email: string
  roles: IUserRole[]
  email_verified_at?: Date | string | null
  phone?: string | null
  userDetail?: IUserDetail | null
  userMetas?: IReponseItemSetting[] | null
  notes?: INote[] | null
  last_login_at: Date | null
  last_login_ip: string | null
  created_at: Date | string
  files?: IFile[] | null
  invitation_sent_at?: Date | string | null
  tags?: ITag[] | null
  is_favorited?: boolean
  notification_settings: IUserNotification
  inbound_email_address?: string | null
  assets?: IAsset[] | null
  direct_manager_id: number | null
  directManager: IUser | null
}
export interface IUserPayload {
  first_name?: string
  last_name?: string
  account_id?: number | null
  email?: string
  password?: string | null
  phone?: string | null
  roles?: number[]
  userDetail?: IUserDetail | null
  userMetas?: Array<Partial<IReponseItemSetting>> | null
  avatar?: File | null
  tags?: number[] | null
  existing_avatar_id?: number | null
  notification_settings?: IUserNotification
  inbound_email_address?: string | null
  direct_manager_id?: number
}

export interface IFilterUser extends IFilter {
  filterRole?: OptionValue | null
  filterStatus?: OptionValue | null
  filterAccount?: OptionValue | null
}

export const getUsers = async ({
  search = '',
  sortBy = 'first_name',
  sortDirection = 'asc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
  filterRole = null,
  filterStatus = null,
  filterAccount = null,
}: IFilterUser) => {
  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortBy}`
  const endPoint = generateQueryOfEndPoint('/api/users', {
    sort,
    'filter[search]': search,
    'filter[account_id]': filterAccount?.value || null,
    // 'filter[role]': filterRole?.value || null,
    // 'filter[status]': filterStatus?.value || null,
    limit: pageSize,
    page: currentPage,
  })
  return client.get(`${endPoint}include=roles,userDetail`)
}

export const getRecentUsers = async (props: IFilterUser) => {
  return getUsers({
    ...props,
    sortBy: 'recent',
    sortDirection: 'asc',
    pageSize: PAGE_SIZE_RECENT,
    currentPage: 1,
  })
}

export const createUser = async (payload: IUserPayload) => {
  return client.post('/api/users', payload)
}

export const addUserAvatar = async (
  id: number,
  { avatar }: { avatar: File | null },
) => {
  if (!avatar) {
    return
  }
  const formData = new FormData()
  formData.append('avatar', avatar)
  formData.append('_method', 'PUT')
  return client.post(`/api/users/${id}`, formData)
}

export const editUser = (userId: number, payload: Partial<IUserPayload>) => {
  return client.put(`/api/users/${userId}`, payload)
}
export const deleteUser = async (id: number) => {
  return client.delete(`/api/users/${id}`)
}

export const detailUser = async (id: number) => {
  return client.get(
    `api/users/${id}?include=account,roles,userDetail,userMetas,tags,inboundEmailAddress,assets,directManager`,
  )
}

export const resendInvitation = async (id: number) => {
  return client.post<{ data: IUser }>(`/api/users/${id}/invitations`)
}
