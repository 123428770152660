import { AxiosProgressEvent, AxiosResponse } from 'axios'
import cx from 'classnames'
import { AnimatePresence } from 'framer-motion'
import { useEffect, useMemo, useState } from 'react'

import { Button, Modal, ModalProps } from '../../../../components'
import { ModalConfirm } from '../../../../components/Modal/Confirm'
import { useAsync } from '../../../../hooks'
import { usePermission } from '../../../../hooks/usePermission'
import { ErrorCircle, SuccesCircle } from '../../../../icons'
import {
  createFile,
  deleteFile,
  editFile,
  IFile,
  IFilePayload,
} from '../../../../services/files'
import { byteToSize, MAX_FILE_SIZE } from '../../../../utils/files'
import { OptionValueTag } from '../../../../utils/form'
import {
  mapOptionValuetoTagArrId,
  pluralText,
} from '../../../../utils/functions'
import { PERMISSIONS } from '../../../../utils/permission'
import toast from '../../../../utils/toast'
import { FileItem } from './FileItem'
import { FileTagsSelect } from './Tags'

interface Props extends Omit<ModalProps, 'children'> {
  onSuccess?: () => void
  onClose: () => void
  files: File[]
  payloads?: IFilePayload | null
}
export type TLocalFile = {
  id: number
  rawFile: File
  file: IFile | null
  tags?: OptionValueTag[] | null
  name?: string | null
  note?: string | null
  status: string
  statusMsg?: string
}

export type TDataEditFile = {
  tags?: OptionValueTag[] | null
  name?: string | null
  note?: string | null
}

export const FILE_STATUS_SUCCESS = 'SUCCESS'
export const FILE_STATUS_INPROGRESS = 'INPROGRESS'
export const FILE_STATUS_FAIL = 'FAIL'

const countStatusFiles = (file: TLocalFile[], status: string) => {
  return file.reduce(
    (total, item) => (item.status === status ? total + 1 : total),
    0,
  )
}
const updateFileByIndex = (
  list: TLocalFile[],
  index: number,
  data: Partial<TLocalFile>,
) => {
  const detailIndex = list.findIndex(item => item.id === index)
  if (detailIndex >= 0) {
    return [
      ...list.slice(0, detailIndex),
      {
        ...list[detailIndex],
        ...data,
      },
      ...list.slice(detailIndex + 1),
    ]
  }
  return list
}

export const ModalUploadFile = ({
  onClose,
  onSuccess,
  files,
  payloads,
  ...props
}: Props) => {
  const controller = useMemo(() => new AbortController(), [])
  const initLocalFiles = files.map((item, index) => ({
    id: index,
    rawFile: item,
    file: null,
    status: FILE_STATUS_INPROGRESS,
  }))
  const deleteAsync = useAsync({ showNotifOnError: false })
  const { canView, canCreate } = usePermission({ name: PERMISSIONS.TAG })
  const [confirmClose, setConfirmClose] = useState(false)
  const [localFiles, setLocalFiles] = useState<TLocalFile[]>(initLocalFiles)
  const [uploading, setUploading] = useState(true)
  const [percentProgress, setPercentProgress] = useState<Record<
    string,
    number
  > | null>(null)
  const [saving, setSaving] = useState(false)
  const [tagAllFiles, setTagAllFiles] = useState<OptionValueTag[] | null>(null)
  const canUseTag = canCreate && canView
  const totalFileLocal = files?.length || 0

  const totalSuccess = useMemo(
    () => countStatusFiles(localFiles, FILE_STATUS_SUCCESS),
    [localFiles],
  )
  const totalFail = useMemo(
    () => countStatusFiles(localFiles, FILE_STATUS_FAIL),
    [localFiles],
  )
  const totalInprogress = useMemo(
    () => countStatusFiles(localFiles, FILE_STATUS_INPROGRESS),
    [localFiles],
  )

  const countProgress = (progress: AxiosProgressEvent, id: number) => {
    const { loaded, total = 0 } = progress
    const percentageProgress = Math.floor((loaded / total) * 100)
    setPercentProgress(prev => ({
      ...prev,
      [id]: percentageProgress === 100 ? 99 : percentageProgress, // delay to waiting BE return data
    }))
  }

  const handleUpload = async (data: IFilePayload, localId: number) => {
    try {
      if (data.file && data.file?.size > MAX_FILE_SIZE) {
        const newFile = {
          file: null,
          status: FILE_STATUS_FAIL,
          statusMsg: `Max file size must be no more than ${byteToSize(
            MAX_FILE_SIZE,
          )}`,
        }
        setLocalFiles(prev => updateFileByIndex(prev, localId, newFile))
        return
      }
      const result = await createFile(
        {
          ...payloads,
          ...data,
        },
        {
          onUploadProgress: (progress: AxiosProgressEvent) => {
            countProgress(progress, localId)
          },
          signal: controller.signal,
        },
      )
      if (result?.data.data) {
        const newFile = {
          file: result.data.data,
          status: FILE_STATUS_SUCCESS,
          percentageProgress: 100,
        }
        setLocalFiles(prev => updateFileByIndex(prev, localId, newFile))
      }
    } catch (error: any) {
      const newFile = {
        file: null,
        status: FILE_STATUS_FAIL,
        percentageProgress: 100,
        statusMsg: error?.message || null,
      }
      setLocalFiles(prev => updateFileByIndex(prev, localId, newFile))
    }
  }
  const handleUploadFile = async (data: File[]) => {
    if (totalSuccess > 0) {
      return
    }
    setUploading(true)
    for (let i = 0; i < data.length; i++) {
      handleUpload(
        {
          file: data[i],
        },
        i,
      )
    }
  }

  const handleDeleteFile = async (localId: number) => {
    const detailIndex = localFiles.findIndex(item => item.id === localId)
    if (detailIndex >= 0) {
      const fileId = localFiles[detailIndex]?.file?.id || null
      if (fileId) {
        await deleteAsync.execute(deleteFile(fileId))
      }
      const newLocalFile = [...localFiles].filter(item => item.id !== localId)
      setLocalFiles(newLocalFile)
    }
  }

  const handleRetry = async (file: File, localId: number) => {
    if (!uploading) {
      setUploading(true)
    }
    setLocalFiles(prev =>
      updateFileByIndex(prev, localId, { status: FILE_STATUS_INPROGRESS }),
    )
    handleUpload({ file }, localId)
  }

  const handleRetryAll = async () => {
    if (totalFail === 0) {
      return
    }
    const listFileFail = localFiles.filter(
      item => item.status === FILE_STATUS_FAIL,
    )
    for (let index = 0; index < listFileFail.length; index++) {
      handleRetry(listFileFail[index].rawFile, listFileFail[index].id)
    }
  }

  const handleEditFile = (localId: number, data: TDataEditFile) => {
    setLocalFiles(prev => updateFileByIndex(prev, localId, data))
  }

  const handleSave = async () => {
    if (saving) {
      return
    }
    try {
      const tagAllFileIds = mapOptionValuetoTagArrId(tagAllFiles)
      const request: Promise<AxiosResponse>[] = []
      localFiles.forEach(item => {
        let payload: IFilePayload = {}
        const tagLocalFileId = mapOptionValuetoTagArrId(item.tags)
        const tagIds = tagLocalFileId.length ? tagLocalFileId : tagAllFileIds // file has already tag , not apply all tags for it
        if (item.file?.id) {
          if (tagIds.length > 0 && canUseTag) {
            payload = { ...payload, tags: tagIds }
          }
          if (!!item.name) {
            payload = { ...payload, name: item.name }
          }
          if (!!item.note) {
            payload = { ...payload, note: item.note }
          }
          console.log('payload', item)
          if (Object.keys(payload).length > 0) {
            request.push(editFile(item.file?.id, payload))
          }
        }
      })
      if (request.length > 0) {
        setSaving(true)
        await Promise.allSettled(request)
      }
      handleCloseWhenPressSave()
    } finally {
      setSaving(false)
    }
  }

  const handleCheckBeforeClose = async () => {
    if (totalFail > 0 && totalSuccess > 0) {
      // will not show if has only fail file
      setConfirmClose(true)
    } else {
      handleSave()
    }
  }

  const handleCloseWhenPressSave = () => {
    if (!uploading) {
      if (totalSuccess > 0) {
        onSuccess?.()
        toast.success({
          title: `${totalSuccess} ${pluralText('file', totalSuccess)} uploaded`,
        })
      }
    } else {
      controller.abort()
    }
    onClose()
  }
  const handleDeleteFileUploaded = async () => {
    const listIdFileUploaded = localFiles
      .filter(item => item.file?.id)
      .map(item => item?.file?.id) as number[]
    for (let i = 0; i < listIdFileUploaded.length; i++) {
      await deleteAsync.execute(deleteFile(listIdFileUploaded[i]))
    }
  }

  const handleClose = async () => {
    if (uploading) {
      controller.abort()
    }
    await handleDeleteFileUploaded()
    onClose()
  }

  useEffect(() => {
    const totalUploaded = totalFail + totalSuccess
    if (totalUploaded === totalFileLocal && uploading) {
      setUploading(false)
    }
  }, [totalFail, totalSuccess, uploading])

  useEffect(() => {
    handleUploadFile(files)
  }, [])

  return (
    <>
      <Modal
        {...props}
        className='w-[calc(100vw_-_3rem)] h-[calc(100vh_-_3rem)] relative p-0'
        onClose={handleClose}
      >
        <span
          className='cursor-pointer font-icon-close text-black-400 absolute right-2 top-2 z-10 w-10 h-10 flex justify-center items-center'
          onClick={handleClose}
        />
        <div className='h-full flex flex-col' id='modal-upload-file'>
          <div className='px-4 h-14 border-b border-separation-800 flex justify-between pr-14 items-center'>
            <div className='text-base font-medium text-black-800'>
              Upload File
            </div>
            {uploading ? (
              <div className='flex gap-2 items-center text-body'>
                <span className='font-icon-loading animate-spin-slow inline-block' />
                Uploading {totalInprogress}{' '}
                {pluralText('file', totalInprogress)}
              </div>
            ) : totalSuccess === totalFileLocal ? (
              <div className='text-green-900 flex gap-2 text-body font-medium items-center'>
                <SuccesCircle />
                All File Uploaded
              </div>
            ) : (
              <div className='flex items-center gap-3'>
                <div className='flex gap-2 text-body items-center'>
                  <SuccesCircle className='text-green-900 ' />
                  <span>
                    Uploaded:{' '}
                    <span className='font-medium'>
                      {totalSuccess} {pluralText('file', totalSuccess)}
                    </span>
                  </span>
                </div>
                <div className='h-6 bg-separation-800 w-px' />
                <div className='flex gap-2 text-body items-center'>
                  <ErrorCircle className='text-red-900' />
                  <span>
                    Failed:{' '}
                    <span className='font-medium'>
                      {totalFail} {pluralText('file', totalFail)}
                    </span>
                  </span>
                </div>
                <Button
                  variant='ternary'
                  innerClassName='!px-3 gap-2'
                  onClick={handleRetryAll}
                >
                  <span className='font-icon-refresh' />
                  Retry
                </Button>
              </div>
            )}
          </div>
          <div className='flex-1 bg-separation-100 overflow-hidden flex flex-col'>
            <div className='flex-1 overflow-y-scroll scroll-smooth'>
              <div className='p-4 grid gap-8 grid-cols-[repeat(auto-fill,minmax(18.75rem,1fr))]'>
                {localFiles.map(item => (
                  <FileItem
                    file={item}
                    key={item.id}
                    onRetry={() => handleRetry(item.rawFile, item.id)}
                    onDelete={() => handleDeleteFile(item.id)}
                    canUseTag={canUseTag}
                    onEditFile={data => handleEditFile(item.id, data)}
                    percentProgress={percentProgress?.[item.id] || null}
                  />
                ))}
              </div>
            </div>
          </div>
          <div
            className={cx(
              'flex flex-col sm:flex-row gap-2 px-4 py-3 border-t border-separation-800 items-center',
              canUseTag ? 'justify-between' : 'justify-end',
            )}
          >
            {canUseTag && (
              <div className='flex gap-2 text-body basis-full sm:basis-3/5 w-full items-center'>
                <div className='font-medium'>Add tag to all files</div>
                <div className='flex-1 w-full'>
                  <FileTagsSelect
                    placeholder='# Add tags (optional)'
                    disabled={uploading}
                    onSelect={setTagAllFiles}
                    animation={undefined}
                    variant='inline'
                  />
                </div>
                <div className='text-black-400'>
                  {tagAllFiles ? tagAllFiles.length : 0}/5
                </div>
              </div>
            )}
            <div className='flex gap-2'>
              <Button
                variant='ternary'
                onClick={handleClose}
                innerClassName='!px-4'
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                onClick={handleCheckBeforeClose}
                className='bg-primary-900'
                innerClassName='!px-10'
                disabled={uploading}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <AnimatePresence initial={false}>
        {confirmClose && (
          <ModalConfirm
            onClose={() => setConfirmClose(false)}
            title={`${totalFail} ${pluralText(
              'file',
              totalFail,
            )} are failed. Do you want to save without those files anyway?`}
            submitText='Cancel'
            cancelText='Save anyway'
            onCancel={handleSave}
            onSubmit={() => setConfirmClose(false)}
          />
        )}
      </AnimatePresence>
    </>
  )
}
