import cx from 'classnames'
import { memo, SyntheticEvent, useEffect, useState } from 'react'

import ImgThumbnail from '../../assets/images/img_thumbnail.svg'
import { Spinner } from '../Spinner'

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  character?: string | null
  showLoading?: Boolean
  loadingClassName?: string
}
export const Image = memo(
  ({
    character,
    src,
    showLoading = false,
    onError,
    loadingClassName = '',
    ...props
  }: Props) => {
    const [isError, setError] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const handleError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
      setError(true)
      onError?.(e)
    }

    const handleLoad = () => {
      setLoading(false)
    }

    const renderImage = () => (
      <img
        {...props}
        onLoad={handleLoad}
        onError={handleError}
        src={isError || !src ? ImgThumbnail : src}
      />
    )
    useEffect(() => {
      if (src !== '') {
        setError(false)
        setLoading(true)
      }
    }, [src])

    if (isError && character) {
      return (
        <span className='absolute w-full h-full inset-0 items-center justify-center bg-separation-800 text-[1.5rem] font-medium tracking-wider flex'>
          {character}
        </span>
      )
    }
    const loading = isLoading && showLoading
    if (showLoading) {
      return (
        <div className='relative'>
          {renderImage()}
          {loading && (
            <>
              <div className='absolute bg-black-900/50 z-[1000] inset-0' />
              <Spinner
                size='small'
                className={cx('absolute z-[1000] inset-0', loadingClassName)}
              />
            </>
          )}
        </div>
      )
    }
    return renderImage()
  },
)
