import { initFilter } from '../services/client'
import { IFilterOpportunities } from '../services/opportunities'
import { compareFilterPagination } from './functions'

export const OPPORTUNITY_STAGE = [
  'Analysis Required',
  'Contact Manufacturer',
  'Discard',
  'Qualified Lead',
  'Quote Created',
  'Shelved',
  'Site Visit Required',
  'Subcontractor Input Required',
  'Ready to Quote',
]

export const initFilterOpportunities: IFilterOpportunities = {
  ...initFilter,
  search: '',
  filterAccount: null,
}

export const isHasFilterOpportunities = (filter: IFilterOpportunities) => {
  return (
    Boolean(filter.filterAccount) ||
    compareFilterPagination(filter, initFilterOpportunities)
  )
}
