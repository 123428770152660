import cx from 'classnames'
import React from 'react'

import { Price, Subscription, SubscriptionItem } from '../../services/billing'
import { SubscriptionProductSelect } from '../../shared/Select/Subscription/SubscriptionProduct'
import { currencyFormat } from '../../utils/functions'
import { Button } from '../Button'

interface SubscriptionProductsListProps {
  items: SubscriptionItem[]
  record: Subscription
  selectedSubscription: Subscription
  isEdit: boolean
  products: Price[]
  onRemoveProduct: (id: number) => void
  selectedProducts: string[]
  setSelectedProducts: React.Dispatch<React.SetStateAction<string[]>>
}

export const SubscriptionProductsList = ({
  items,
  record,
  selectedSubscription,
  isEdit,
  products,
  onRemoveProduct,
  selectedProducts,
  setSelectedProducts,
}: SubscriptionProductsListProps) => {
  return (
    <>
      {items.map((item, index) => (
        <div
          key={item.id}
          className={cx(
            'bg-white rounded-lg p-1',
            items.length > index + 1 && 'mb-4',
          )}
        >
          <div className='cursor-pointer flex items-center gap-2'>
            <span
              className={
                'bg-gray-100 rounded aspect-square w-5 h-5 text-center'
              }
            >
              {index + 1}
            </span>
            <p className='font-semibold'>
              {products.find(price => price.price_id === item.stripe_price)
                ?.product_name || 'unknown'}
              <span className='text-gray-500'>
                (
                {currencyFormat(
                  (products.find(price => price.price_id === item.stripe_price)
                    ?.unit_amount || 0) / 100,
                )}
                )
              </span>
            </p>

            {isEdit && record.id === selectedSubscription?.id && (
              <Button
                onClick={() => onRemoveProduct(item.id)}
                innerClassName='px-4'
                className='w-fit hover:bg-danger'
                size='small'
              >
                <div className='flex gap-2 items-center'>
                  <span className='font-icon-remove ' />
                </div>
              </Button>
            )}
          </div>
        </div>
      ))}
      {isEdit &&
        record.id === selectedSubscription?.id &&
        selectedSubscription?.items.length !== products.length && (
          <SubscriptionProductSelect
            parentProducts={products}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            except={selectedSubscription?.items}
          />
        )}
    </>
  )
}
