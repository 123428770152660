import cx from 'classnames'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import shallow from 'zustand/shallow'

import Logo from '../../assets/images/logo_system.png'
import { customerNavigations, INavigation } from '../../configs/navigations'
import { PATHNAME } from '../../configs/routes'
import { useAsync } from '../../hooks'
import { useCustomerPortalCtx } from '../../pages/CustomerPortal/Context'
import { getCustomerBilling } from '../../services/billing'
import { ComponentPermisison } from '../../shared/Permission/ComponentPermission'
import useStore, { Store } from '../../store'
import {
  checkMenuPermisison,
  PERMISSIONS,
  PERMISSIONS_VIEW,
} from '../../utils/permission'
import { NotificationMenu } from '../MainLayout/NotificationMenu'
import { ProfileMenu } from './ProfileMenu'

const mapStore = ({ auth }: Store) => ({
  permissions: auth.currentUser?.can,
  user: auth.currentUser,
})

export const Header = () => {
  const { pathname } = useLocation()
  const [showBilling, setShowBilling] = useState(false)

  const { dispatch } = useCustomerPortalCtx()
  const { permissions = [], user } = useStore(mapStore, shallow)

  const fetchBillingAsync = useAsync({
    showNotifOnError: false,
    status: 'idle',
  })

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    await fetchBillingAsync
      .execute(getCustomerBilling(user?.account_id || 0))
      .then(data => {
        dispatch({ billingResult: data })
        setShowBilling(true)
      })
      .catch(async error => {
        if (
          error.errorCode === 'account_not_stripe_customer' &&
          !(user?.roles[0].name === 'admin')
        ) {
          setShowBilling(false)
        } else {
          setShowBilling(true)
        }
      })
  }

  const renderMenuItem = (menuItem: INavigation) => {
    const { label, navLink, query = '', key } = menuItem
    const active = pathname.includes(navLink)

    return (
      <div key={`${key}-${label}`} className='relative h-full flex gap-1'>
        <Link
          to={navLink! + query}
          className={cx(
            'text-body group text-black-800 px-3 rounded-t-lg inline-flex relative items-center h-full border border-transparent gap-2',
          )}
        >
          {label}
          <div
            className={cx(
              'absolute rounded-lg bg-primary-900 bottom-0 w-full h-1 left-0 group-hover:block hidden',
              active && '!block',
            )}
          />
        </Link>
      </div>
    )
  }

  const filterMenus = customerNavigations
    .filter(item => checkMenuPermisison(PERMISSIONS[item.key], permissions))
    .filter(item => {
      if (item.key === 'CUSTOMER_BILLING') {
        return showBilling
      }
      return true
    })

  return (
    <div className='flex w-full h-full px-4 sm:px-6 gap-1 items-center justify-between bg-white shadow-card'>
      <Link to='/' className='block w-7' aria-label='logo'>
        <img
          src={Logo}
          className='cursor-pointer w-full'
          width='28'
          height='28'
          alt='Logo'
        />
      </Link>
      <div className='hidden sm:flex gap-x-1 flex-wrap overflow-hidden h-[3rem] ml-4'>
        {filterMenus.map(renderMenuItem)}
      </div>
      <div className='item flex gap-[1.625rem] items-center text-[1.125rem] ml-4 relative'>
        <NotificationMenu />
        <ComponentPermisison name={PERMISSIONS.SETTING} type={PERMISSIONS_VIEW}>
          <Link to={`/${PATHNAME.settings}`}>
            <span className='font-icon-setting' />
          </Link>
        </ComponentPermisison>
        <ProfileMenu user={user} />
      </div>
    </div>
  )
}
