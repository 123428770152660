import { OptionValue } from '../utils/form'
import { generateQueryOfEndPoint } from '../utils/functions'
import { IAccount } from './accounts'
import client, { IFilter, PAGE_SIZE, PAGE_SIZE_RECENT } from './client'
import { IContact } from './contacts'
import { IInvoice } from './invoices'
import { INote } from './notes'
import { IService } from './services'
import { ITag } from './tags'
import { ITaxVendor } from './taxVendors'

export interface ICms {
  id: number
  avatar_preview_url?: string | null
  avatar_url?: string | null
  account_id?: number | null
  account?: IAccount | null
  services?: string[] | null
  name: string | null
  coverage_area?: string | null
  city_id?: string | null
  csid?: string | null
  main_phone?: string | null
  office_phone?: string | null
  mobile_phone?: string | null
  fax_phone?: string | null
  other_phone?: string | null
  contact?: IContact | null
  contacts?: IContact[] | null
  created_at: Date | string
}
export interface ICmsPayload
  extends Omit<
    ICms,
    'id' | 'name' | 'account' | 'created_at' | 'contact' | 'contacts'
  > {
  name?: string | null
  contact?: number | null
  contacts?: number[] | null
}

export interface IFilterCms extends IFilter {
  filterAccount?: OptionValue | null
}

export type TPremiumServices = {
  name: string
  resale_price: number | null
  cost?: number | null
}

export type TCommunicationOption = {
  key: string
  value: string
}
export interface IMonitoringCommunication {
  name: string
  options: TCommunicationOption[] | null
}
export interface IMonitoring {
  id: number
  account_id: number
  account: IAccount
  central_monitoring_station_id: number
  centralMonitoringStation: ICms
  name: string
  primary_account_number?: string | null
  primary_receiver_number?: string | null
  secondary_account_number?: string | null
  secondary_receiver_number?: string | null
  city_id?: string | null
  csid?: string | null
  street_address?: string | null
  street_address_2?: string | null
  city?: string | null
  state?: string | null
  zip?: string | null
  country?: string | null
  local_police_number?: string | null
  local_fire_number?: string | null
  local_ems_number?: string | null
  payment_frequency?: string | null
  amount?: number | null
  service_types?: string[] | null
  service_start_date?: Date | string | null
  install_date?: Date | string | null
  premium_services?: TPremiumServices[] | null
  is_billing_enabled?: number | null
  passcode?: string | null
  panel_model?: string | null
  panel_revision?: string | null
  created_at: Date | string
  communications?: IMonitoringCommunication[] | null
  serviceTickets?: IService[] | null
  invoices?: IInvoice[] | null
  notes?: INote[] | null
  taxVendor?: ITaxVendor | null
  inbound_email_address?: string | null
  tags?: ITag[] | null
}

export interface IMonitoringPayload
  extends Omit<
    IMonitoring,
    | 'id'
    | 'name'
    | 'account'
    | 'account_id'
    | 'central_monitoring_station_id'
    | 'centralMonitoringStation'
    | 'created_at'
    | 'invoices'
    | 'tags'
  > {
  name?: string | null
  account_id?: number | null
  central_monitoring_station_id?: number | null
  tags?: number[] | null
}
export interface IFilterMonitoring extends IFilter {
  filterAccount?: OptionValue | null
  filterCMS?: OptionValue | null
}

export const getCMSs = async ({
  search = '',
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
  filterAccount = null,
}: IFilterCms) => {
  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortBy}`
  const endPoint = generateQueryOfEndPoint('/api/centralMonitoringStations', {
    sort,
    'filter[search]': search,
    limit: pageSize,
    page: currentPage,
    'filter[account_id]': filterAccount?.value || null,
  })
  return client.get(`${endPoint}include=account`)
}

export const createCMS = async (payload: ICmsPayload) => {
  return client.post('/api/centralMonitoringStations', payload)
}

export const editCMS = async (id: number, payload: ICmsPayload) => {
  return client.put(`api/centralMonitoringStations/${id}`, payload)
}

export const deleteCMS = async (id: number) => {
  return client.delete(`/api/centralMonitoringStations/${id}`)
}

export const detailCMS = async (id: number) => {
  return client.get(
    `api/centralMonitoringStations/${id}?include=account,contact`,
  )
}

export const getMonitorings = async ({
  search = '',
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
  filterAccount = null,
  filterCMS = null,
}: IFilterMonitoring) => {
  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortBy}`
  const endPoint = generateQueryOfEndPoint('/api/monitoringServices', {
    sort,
    'filter[search]': search,
    limit: pageSize,
    page: currentPage,
    'filter[account_id]': filterAccount?.value || null,
    'filter[central_monitoring_station_id]': filterCMS?.value || null,
  })
  return client.get(
    `${endPoint}include=account,centralMonitoringStation,invoices,inboundEmailAddress`,
  )
}

export const getRecentMonitorings = async (props: IFilterMonitoring) => {
  return getMonitorings({
    ...props,
    sortBy: 'recent',
    sortDirection: 'asc',
    pageSize: PAGE_SIZE_RECENT,
    currentPage: 1,
  })
}

export const createMonitoring = async (payload: IMonitoringPayload) => {
  return client.post('/api/monitoringServices', payload)
}

export const editMonitoring = async (
  id: number,
  payload: IMonitoringPayload,
) => {
  return client.put(`api/monitoringServices/${id}`, payload)
}

export const deleteMonitoring = async (id: number) => {
  return client.delete(`/api/monitoringServices/${id}`)
}

export const detailMonitoring = async (id: number) => {
  return client.get(
    `api/monitoringServices/${id}?include=account,centralMonitoringStation,taxVendor,serviceTickets,invoices,notes,inboundEmailAddress,tags`,
  )
}
