import { Avatar } from '../../../components/Avatar'
import { AsyncSelect } from '../../../components/Forms/AsyncSelect'
import { Props as SelectProps } from '../../../components/Forms/Select'
import {
  getAccounts,
  IAccount,
  IFilterAccounts,
} from '../../../services/accounts'
import { PAGE_SIZE_DROPDOWN } from '../../../services/client'
import {
  ACCOUNT_TYPES_CUSTOMER,
  renderAccountName,
} from '../../../utils/accounts'
import { OptionValue } from '../../../utils/form'

export interface Props extends Omit<SelectProps, 'options' | 'onSelect'> {
  selected?: OptionValue | null
  onSelect?: (value: OptionValue | null, valueRaw?: IAccount | null) => void
  className?: string
  excludesValues?: number[]
  filter?: IFilterAccounts | null
  fetchOnFirst?: boolean
  withDetails?: boolean
}

export const AccountSelect = ({
  selected = null,
  className = '',
  onSelect,
  filter = {
    filterAccountType: ACCOUNT_TYPES_CUSTOMER,
  },
  excludesValues = [],
  fetchOnFirst = false,
  withDetails = false,
  ...props
}: Props) => {
  const handleGetAccounts = async ({ search = '', page = 1 } = {}) => {
    try {
      const { data } = await getAccounts({
        search,
        currentPage: page,
        pageSize: PAGE_SIZE_DROPDOWN,
        ...filter,
      })
      return { data: data.data, totalPage: data.meta.last_page }
    } catch {
      return { data: [], totalPage: 1 }
    }
  }

  const handleSelect = (item: any, itemFull: IAccount) => {
    const value = !!item
      ? {
          value: +item.value,
          label: renderAccountName(itemFull),
        }
      : null
    onSelect?.(value, itemFull)
  }

  const mapOptions = (data: IAccount[]) => {
    return data.map(item => ({
      value: item.id,
      label: (
        <div className='flex items-center gap-2'>
          <Avatar
            hashId={`account-${item.id}`}
            src={
              item.parentAccount?.avatar_preview_url || item.avatar_preview_url
            }
          />
          <div className='text-body flex flex-col flex-1 w-full'>
            {renderAccountName(item)}
            {withDetails && (
              <div className='text-black-400 inline-flex gap-2 items-center'>
                <span>{item.email}</span>
                <span className='w-px h-4 bg-black-400' />
                <span>{item.phone_no}</span>
              </div>
            )}
          </div>
        </div>
      ),
      displayLabel: renderAccountName(item),
    }))
  }

  return (
    <AsyncSelect
      placeholder='All Accounts'
      labelInValue
      allowClear
      dropdownMatchSelectWidth={300}
      {...props}
      value={
        selected?.value
          ? { value: selected.value + '', label: selected.label }
          : null
      }
      onChange={handleSelect}
      className={className}
      fetcher={handleGetAccounts}
      dropdownClassName='w-full'
      optionLabelProp='displayLabel'
      options={mapOptions}
      fetchOnFirst={fetchOnFirst}
    ></AsyncSelect>
  )
}
