import cx from 'classnames'

import { Avatar, Props as PropsAvatar } from '../components/Avatar'
import { IAccount } from '../services/accounts'
import { renderAccountName } from '../utils/accounts'

interface Props {
  account?: IAccount | null
  showAvatar?: boolean
  showIconParent?: boolean
  showParent?: boolean
  textClassName?: string
  className?: string
}

export const AccountAvatar = ({
  account,
  showIconParent = false,
  optionAvatar = {},
}: {
  account: IAccount
  showIconParent?: boolean
  optionAvatar?: PropsAvatar | null
}) => {
  return (
    <div className={cx('relative', showIconParent && 'inline-flex')}>
      {account ? (
        <Avatar
          {...optionAvatar}
          hashId={`account-${account.id}`}
          src={
            account.parentAccount?.avatar_preview_url ||
            account.avatar_preview_url
          }
        />
      ) : (
        <Avatar />
      )}
      {account?.type === 'Lead' && showIconParent && (
        <span className='font-icon-lead text-purple-700 text-[0.875rem]' />
      )}
      {account?.parentAccount && showIconParent && (
        <div className='absolute bottom-0 -right-2 w-5 h-5 bg-white flex justify-center items-center rounded-full'>
          <span className='font-icon-parent_account text-purple-700 text-[0.875rem]' />
        </div>
      )}
    </div>
  )
}
export const AccountName = ({
  account,
  showAvatar,
  showIconParent = true,
  showParent = true,
  textClassName = '',
  className = '',
}: Props) => {
  if (!account) {
    return null
  }
  return (
    <div className={cx('inline-flex gap-2 items-center', className)}>
      {showAvatar && (
        <div className='flex-1'>
          <AccountAvatar account={account} />
        </div>
      )}
      <div className={cx('underline', textClassName)}>
        {renderAccountName(account, showParent)}
      </div>
      {account.type === 'Lead' && showIconParent && (
        <span className='font-icon-lead text-purple-700 text-[0.875rem]' />
      )}
      {account.parentAccount && showIconParent && (
        <span className='font-icon-parent_account text-purple-700 text-[0.875rem]' />
      )}
    </div>
  )
}
