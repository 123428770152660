import { saveAs } from 'file-saver'
import { has, sampleSize } from 'lodash'

import { IFilter } from '../services/client'
import { OptionValue, OptionValueTag } from './form'

export const CHAR_RANDOM =
  '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
export function copyToClipBoard(text: string) {
  const textarea = document.createElement('textarea')
  textarea.value = text
  textarea.style.display = 'fixed'
  textarea.style.zIndex = '-99999999'
  textarea.style.opacity = '0'
  document.body.appendChild(textarea)
  textarea.select()
  document.execCommand('copy')
  document.body.removeChild(textarea)
}

export const downloadFile = (content: string | Blob, name?: string) => {
  saveAs(content, name || '')
}

export function leadZero(num: string | number) {
  return (num + '').padStart(2, '0')
}

export function roundDecimals(num: number, decimals = 2) {
  return Math.round((num + Number.EPSILON) * 10 ** decimals) / 10 ** decimals
}

export function isMobile() {
  let check = window.innerWidth < 768
  const ua = navigator.userAgent || navigator.vendor
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      ua,
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(
      ua.substr(0, 4),
    )
  ) {
    check = true
  }
  return check
}

export const filterByKeyword = (
  list: Array<any>,
  type: string,
  keyword: string,
) => {
  if (type === '' || keyword === '') {
    return list
  }
  if (list.length === 0) {
    return []
  }
  if (!has(list[0], type)) {
    return list
  }
  return list.filter(item =>
    item[type].toString().toLowerCase().includes(keyword.toLowerCase()),
  )
}

export const randomString = (length = 12) => {
  const pwd = sampleSize(CHAR_RANDOM, length)
  return pwd.join('')
}

export const currencyFormat = (
  num: number | null,
  toFixed = 0,
  isShowCurrency = true,
) => {
  if (!num && num !== 0) {
    return ''
  }
  let number = num
  if (typeof number === 'string') {
    number = +number
  }
  const newFixed = number.toString().includes('.') ? 2 : toFixed
  const newNum = number
    .toFixed(newFixed)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  const preFix = isShowCurrency ? '$' : ''
  return preFix + newNum
}

export const formatBytes = (bytes: number, type = '', decimals = 2) => {
  if (bytes === 0) {
    return '0B'
  }

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['B', 'KB', 'MB', 'GB']
  let i
  if (type !== '') {
    i = sizes.findIndex(item => item.toLowerCase() === type.toLowerCase())
  }
  i = i || Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i]
}

export const updateIndex = <T extends { id?: number | string }>(
  list: T[],
  id: number | string,
  data: Partial<T>,
) => {
  const index = list.findIndex(item => item.id === id)
  if (index >= 0) {
    const newList = [
      ...list.slice(0, index),
      { ...list[index], ...data },
      ...list.slice(index + 1),
    ]
    return newList
  }
  return list
}

export const updateOrAddNewIndex = <T extends { id?: number | string }>(
  list: T[],
  id: number | string,
  data: Partial<T>,
) => {
  const index = list.findIndex(item => item.id === id)
  if (index >= 0) {
    return updateIndex(list, id, data)
  } else {
    return [...list, { id, ...data }]
  }
}

export const parserHtmlToText = (html?: string | null) => {
  if (!html || html.length === 0) {
    return ''
  }
  const dom = new DOMParser().parseFromString(html || '', 'text/html')
  const newInfo = dom.body.textContent
  return newInfo || ''
}

export const splitParagraph = (text = '', limit = 10) => {
  if (text.length === 0) {
    return text
  }
  const arrText = text.split(' ')
  const totalArrText = arrText.length
  if (totalArrText < limit) {
    return text
  }
  let newText = ''
  arrText.forEach((item, index) => {
    if (index < limit) {
      newText += ' ' + item
    }
  })
  return newText + '...'
}

export const mapOptionValuetoArrId = (opt?: OptionValue[] | null) => {
  if (!opt || opt.length === 0) {
    return []
  }
  const result: number[] = []
  opt.forEach(item => {
    if (item.value) {
      result.push(item.value)
    }
  })
  return result
}
export const mapOptionValuetoTagArrId = (opt?: OptionValueTag[] | null) => {
  if (!opt || opt.length === 0) {
    return []
  }
  const result: number[] = []
  opt.forEach(item => {
    if (item.key) {
      result.push(+item.key)
    }
  })
  return result
}

export const generateQueryOfEndPoint = (
  path: string,
  query: Record<string, string | number | boolean | null | undefined>,
) => {
  let newQuery = ''
  const objQuery = Object.entries(query)
  objQuery.forEach(item => {
    const [key, value] = item
    if (!!value) {
      newQuery += `${key}=${value}&`
    }
  })
  return path + '?' + newQuery
}

export const truncatedText = (name: string, length = 18) => {
  if (name.length <= length) {
    return name
  }
  const halfLength = Math.floor(length / 2)
  const firstOfName = name.slice(0, halfLength)
  const lastOfName = name.slice(-halfLength)
  return `${firstOfName}...${lastOfName}`
}

export function reOrderList<T>(
  array: Array<T>,
  fromIndex: number,
  toIndex: number,
): Array<T> {
  if (fromIndex === toIndex || fromIndex === toIndex - 1) {
    return array
  }
  let newOverIndex = toIndex
  if (fromIndex < toIndex) {
    newOverIndex = newOverIndex - 1
  }
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex
  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex =
      newOverIndex < 0 ? array.length + newOverIndex : newOverIndex
    const [item] = array.splice(startIndex, 1)
    array.splice(endIndex, 0, item)
  }

  return array
}

export const printElement = (id: string) => {
  const element = document.getElementById(id)
  if (!element) {
    return null
  }
  const framePrintContent = document.getElementById(
    'frame-print-content',
  ) as HTMLIFrameElement
  let contentPrint = null
  if (!framePrintContent) {
    const node = document.createElement('iframe') as HTMLIFrameElement
    node.className = 'absolute w-0 h-0'
    node.id = 'frame-print-content'
    document.body.appendChild(node)
    contentPrint = node.contentWindow
  } else {
    contentPrint = framePrintContent.contentWindow
  }

  if (contentPrint) {
    const header = document.getElementsByTagName('head')[0]
    const htmlContent = `<html><head>${header.innerHTML}</head><body onload="window.print()">${element.innerHTML}</body></html>`
    contentPrint.document.open()
    contentPrint.document.write(htmlContent)
    contentPrint.document.close()
  }
}

export const printHtml = (html: string) => {
  const elemtPrint = document.getElementById('preview_print')
  if (elemtPrint) {
    elemtPrint.innerHTML = html
    printElement('preview_print')
    elemtPrint.innerHTML = ''
  }
}

export const parseKeyFieldToLabel = (name: string) => name.split('_').join(' ')

export const pluralText = (text: string, length: number, newText?: string) => {
  if (length < 2) {
    return text
  }
  if (newText) {
    return newText
  }
  const lastLetter = text[text.length - 1]
  if (lastLetter === 'y') {
    return text.slice(0, -1) + 'ies'
  }
  return `${text}s`
}

export const isIncludeText = (text: string, key: string) =>
  text.toLowerCase().includes(key.toLowerCase())

export const randomStringAndNumber = (length: number) => {
  let result = ''
  for (var i = length; i > 0; --i) {
    result += CHAR_RANDOM[Math.floor(Math.random() * CHAR_RANDOM.length)]
  }
  // Ensure that the result string includes at least one letter and one number
  if (!/[a-zA-Z]/.test(result)) {
    result = result.slice(0, 1) + 'a' + result.slice(2)
  }
  if (!/\d/.test(result)) {
    result = result.slice(0, 2) + '1' + result.slice(3)
  }
  return result
}

export const isValidHex = (hexCode: string) => {
  return /^#?([0-9A-F]{3}){1,2}$/i.test(hexCode)
}

export const stringToHex = (str: string) => {
  let hex = str.replace('#', '')
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  return `#${hex.toUpperCase()}`
}

export const hexToRgba = (hexCode: string, opacity = 1) => {
  const hex = stringToHex(hexCode).replace('#', '')

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100
  }

  return `rgba(${r}, ${g}, ${b}, ${roundDecimals(opacity)})`
}

export const pathResource = (pathName: string, id?: number) =>
  `${window.location.origin}/${pathName}${id ? `/${id}` : ''}`

interface DefaultHierarchyCat<T> {
  id: number
  children?: T[] | null
}

export const findCategoryHierarchyById = <T extends DefaultHierarchyCat<T>>(
  list: T[],
  id: number,
): T | null => {
  let result: T | null = null
  list.forEach(item => {
    if (item.id === id) {
      result = item
    } else {
      if (!!item.children && item.children.length > 0) {
        const dataChild = findCategoryHierarchyById(item.children, id)
        if (dataChild) {
          result = dataChild
        }
      }
    }
  })
  return result
}

export const filterCategoryHierarchy = <T extends DefaultHierarchyCat<T>>(
  list: T[],
  keyword?: string,
  label?: keyof T,
  id?: number,
): T[] => {
  const result: T[] = []
  list.forEach(item => {
    let merge: T | null = null

    const text = label ? item[label] + '' : ''

    if (
      (isIncludeText(text, keyword || '') && !id) ||
      Boolean(id && item.id === id)
    ) {
      merge = item
      if (!!item.children && item.children.length > 0) {
        const dataChild = filterCategoryHierarchy(
          item.children,
          keyword,
          label,
          id,
        )
        merge = {
          ...item,
          children: dataChild,
        }
      }
    } else {
      if (!!item.children && item.children.length > 0) {
        const dataChild = filterCategoryHierarchy(
          item.children,
          keyword,
          label,
          id,
        )
        if (dataChild.length > 0) {
          merge = {
            ...item,
            children: dataChild,
          }
        }
      }
    }
    if (!!merge) {
      result.push(merge)
    }
  })
  return result
}

export const flatHierarchyOfCategoryId = <T extends DefaultHierarchyCat<T>>(
  listData: T[],
  id: number,
  result: T[] = [],
) => {
  let hasId = false
  listData.forEach(item => {
    if (!hasId) {
      if (item.id === id) {
        hasId = true
        result.push(item)
      } else {
        if (item.children && item.children?.length > 0) {
          result.push(item)
          flatHierarchyOfCategoryId(item.children, id, result)
        }
      }
    }
  })
  return result
}

export const compareFilterPagination = (
  filter: IFilter,
  initFilter: IFilter,
) => {
  return Boolean(
    filter?.pageSize !== initFilter.pageSize ||
      filter?.sortBy !== initFilter?.sortBy ||
      filter?.sortDirection !== initFilter?.sortDirection ||
      filter?.currentPage !== initFilter?.currentPage,
  )
}
