import ImgEmptyData from '../../../assets/images/NotFoundBox.png'
import { Button } from '../../../components'
import { PERMISSIONS, PERMISSIONS_CREATE } from '../../../utils/permission'
import { ComponentPermisison } from '../../Permission/ComponentPermission'

interface Props {
  resourceName: keyof typeof PERMISSIONS
  onResetFilter?: () => void
  onCreateFromKeyword?: () => void
}
export const ResourceNotFoundByFilter = ({
  onResetFilter,
  onCreateFromKeyword,
  resourceName,
}: Props) => {
  return (
    <div className='text-body flex flex-col justify-center h-full'>
      <div className='flex-1 flex items-center flex-col justify-center'>
        <div className='w-[13.75rem] mb-4'>
          <img src={ImgEmptyData} alt='empty data' />
        </div>
        <div className='text-base font-medium mb-1'>
          No result matchs your keyword/filter
        </div>
        <div className='text-white-400 mb-8'>
          You can try different keyword/filter or
        </div>
        <div className='flex flex-col gap-2'>
          {onResetFilter && (
            <Button size='large' onClick={onResetFilter}>
              <div className='inline-flex gap-2 items-center'>
                <span className='font-icon-refresh' />
                <span>Reset search & filter</span>
              </div>
            </Button>
          )}
          {onCreateFromKeyword && (
            <ComponentPermisison
              name={PERMISSIONS[resourceName]}
              type={PERMISSIONS_CREATE}
            >
              <Button
                size='large'
                onClick={onCreateFromKeyword}
                innerClassName='inline-flex gap-2 items-center'
                variant='ternary'
              >
                <span className='font-icon-add' />
                <span>Create new object with the keyword</span>
              </Button>
            </ComponentPermisison>
          )}
        </div>
      </div>
    </div>
  )
}
