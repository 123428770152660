import cx from 'classnames'
import { useState } from 'react'

import { Tooltip } from '../../../../../components'
import { useAsync } from '../../../../../hooks'
import { editFile } from '../../../../../services/files'
import { OptionValueTag } from '../../../../../utils/form'
import {
  mapOptionValuetoTagArrId,
  truncatedText,
} from '../../../../../utils/functions'
import {
  PERMISSIONS,
  PERMISSIONS_UPDATE,
} from '../../../../../utils/permission'
import { mapFullTagsToOptionValue } from '../../../../../utils/tag'
import { ComponentPermisison } from '../../../../Permission/ComponentPermission'
import { FileTagsSelect } from '../../Upload/Tags'
import { useFileDetailCtx } from '../Context'

export const FileInfoTags = () => {
  const { file, onUpdateFile } = useFileDetailCtx()
  const optionTags = mapFullTagsToOptionValue(file.tags)
  const [tags, setTags] = useState<OptionValueTag[] | null>(optionTags || null)
  const [isEditMode, setEditMode] = useState(false)
  const { execute } = useAsync({ showNotifOnError: true })

  const handleSaveTags = async () => {
    const tagIds = mapOptionValuetoTagArrId(tags)
    const result = await execute(editFile(file.id, { tags: tagIds }))
    if (result.data.data) {
      const newTags = result.data.data?.tags || []
      onUpdateFile({ tags: newTags })
    }
    setEditMode(false)
  }
  const renderListTags = () =>
    !!tags && (
      <div className='flex flex-wrap gap-1'>
        {tags.map(i => (
          <Tooltip
            content={i.label}
            placement='bottom'
            className='leading-none'
            key={i.value}
            zIndex={4000}
          >
            <div className='bg-separation-200 rounded-[2rem] border border-separation-900 text-xs px-2 py-1'>
              {truncatedText(i?.label || '', 20)}
            </div>
          </Tooltip>
        ))}
      </div>
    )
  const renderAction = () => (
    <>
      {isEditMode ? (
        <div className='flex gap-2 font-medium cursor-pointer items-center'>
          <div className='text-primary-900' onClick={handleSaveTags}>
            Save
          </div>
          <div className='w-px h-3 bg-separation-900' />
          <div className='text-black-400' onClick={() => setEditMode(false)}>
            Cancel
          </div>
        </div>
      ) : (
        <span
          className={cx(
            'cursor-pointer text-black-400 hover:text-black-800',
            isEditMode ? 'font-icon-close' : 'font-icon-edit',
          )}
          onClick={() => setEditMode(prev => !prev)}
        />
      )}
    </>
  )
  return (
    <div className='flex mb-3 pb-3 border-b border-b-separation-900 text-body items-start'>
      <div className='inline-flex items-center w-[7.75rem]'>
        <div className='w-[1.875rem] leading-none'>
          <span className='font-icon-label text-black-400 text-[1rem]' />
        </div>
        <span>Tags</span>
      </div>
      <div className='flex-1'>
        <div className='flex flex-wrap gap-2'>
          {isEditMode ? (
            <FileTagsSelect selected={tags} onSelect={setTags} />
          ) : (
            renderListTags()
          )}
          <ComponentPermisison
            name={PERMISSIONS.FILE}
            type={PERMISSIONS_UPDATE}
          >
            {renderAction()}
          </ComponentPermisison>
        </div>
      </div>
    </div>
  )
}
