import { useRef } from 'react'

import { IFile } from '../../../../services/files'
import { formatBytes } from '../../../../utils/functions'

interface Props {
  file: IFile
  onOpenFile: (file: IFile) => void
}

export const NoteVideo = ({ file, onOpenFile }: Props) => {
  const refVideo = useRef<HTMLVideoElement | null>(null)

  const handleOpenFile = () => {
    refVideo?.current?.pause()
    onOpenFile(file)
  }
  return (
    <div className='col-span-5 p-3 bg-separation-800 flex flex-col gap-2 rounded-lg relative'>
      <div
        className='flex gap-2 items-center cursor-pointer'
        onClick={handleOpenFile}
      >
        <div className='line-clamp-1 font-medium'>{file.name}</div>
        <div>({formatBytes(file.size)})</div>
      </div>
      <video
        src={file.url}
        controls
        className='max-w-full z-10 h-full'
        key={`main-${file.id}`}
        ref={refVideo}
      />
    </div>
  )
}
