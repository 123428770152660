import cx from 'classnames'
import { formatDistanceToNow, isToday, isYesterday } from 'date-fns'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import shallow from 'zustand/shallow'

import Logo from '../../assets/images/logo_system.png'
import ImgEmptyData from '../../assets/images/NotFoundBox.png'
import { Avatar, Badge, Popover, Switch } from '../../components'
import { PATHNAME } from '../../configs/routes'
import { useAsync } from '../../hooks'
import { authorizeNotification } from '../../services/auth'
import {
  deleteAllNotifications,
  getNotifications,
  IFilterNotification,
  INotification,
  markReadNotification,
} from '../../services/notifications'
import { resourceLinks } from '../../shared/Resources/ResourceLink'
import useStore, { Store } from '../../store'
import { formatDateTime } from '../../utils/dateTime'
import { updateIndex } from '../../utils/functions'

declare global {
  interface Window {
    Echo: Echo<any>
    Pusher: Pusher
  }
}

window.Pusher = require('pusher-js')

interface IGroupNotification {
  today?: INotification[]
  yesterday?: INotification[]
  older?: INotification[]
}

const mapState = (state: Store) => ({
  auth: state.auth.currentUser,
  configs: state.configs.currentConfigs,
})

export const NotificationMenu = () => {
  const navigate = useNavigate()
  const [isOpenPopover, setOpenPopover] = useState(false)
  // const refNotification = useRef<NodeJS.Timer | null>(null)
  const [isMarkRead, setMarkRead] = useState(false)
  const [unreadNoti, setUnreadNoti] = useState(0)
  const [isShowUnread, setShowUnRead] = useState(false)
  const [listNoti, setListNoti] = useState<INotification[]>([])
  const listAsync = useAsync({ showNotifOnError: true })
  const detailAsync = useAsync({ showNotifOnError: true })
  const deleteAsync = useAsync({ showNotifOnError: true })
  const { execute, data } = listAsync
  const [currentPage, setCurrentPage] = useState(0)
  const { auth, configs } = useStore(mapState, shallow)
  const configPusher = configs?.pusher

  const loadingList = listAsync.isLoading || deleteAsync.isLoading

  const fetchNotification = async (filter?: IFilterNotification) => {
    const last_page = data?.data.meta?.last_page || null
    if (last_page && currentPage > last_page) {
      return
    }
    return await execute(
      getNotifications({ currentPage: currentPage + 1, ...filter }),
    )
  }

  const handleGetNotification = async (filter?: IFilterNotification) => {
    const result = await fetchNotification(filter)
    if (result.data) {
      const unread_count = result.data?.meta?.unread_count || 0
      if (currentPage < result.data.meta.last_page) {
        setCurrentPage(currentPage + 1)
      }
      setUnreadNoti(unread_count)
      setListNoti(prev => [...prev, ...result.data.data])
    }
  }

  const groupNotifications = (
    notification: INotification[],
  ): IGroupNotification => {
    return notification.reduce(
      (group: IGroupNotification, item: INotification) => {
        const dateFormat = new Date(item.created_at)
        const key = isToday(dateFormat)
          ? 'today'
          : isYesterday(dateFormat)
            ? 'yesterday'
            : 'older'
        const newGroup = group[key] || null
        const newItem = item
        group[key] = newGroup ? [...newGroup, newItem] : [newItem]
        return group
      },
      {},
    )
  }

  const handleScroll = (e: any) => {
    const { scrollHeight, offsetHeight, scrollTop } = e.target as HTMLDivElement
    const last_page = data?.data.meta?.last_page || null
    if (
      scrollHeight <= offsetHeight + scrollTop - 2 &&
      last_page &&
      currentPage < last_page &&
      !loadingList
    ) {
      handleGetNotification()
    }
  }

  const handleMarkRead = async (item: INotification) => {
    if (item.read_at) {
      return null
    }
    const result = await detailAsync.execute(markReadNotification(item.id))
    if (result.data.data) {
      const read_at = result.data.data.read_at as string
      const newData = updateIndex(listNoti, item.id, { read_at })
      setUnreadNoti(prev => prev - 1)
      setListNoti(newData)
    }
  }
  const handleMarkAllRead = async () => {
    await fetchNotification({ mark_as_read: '1', currentPage: 0 })
    setMarkRead(true)
    setUnreadNoti(0)
  }

  const handleClearAll = async () => {
    await deleteAsync.execute(deleteAllNotifications())
    setListNoti([])
    setShowUnRead(false)
    setMarkRead(false)
    setUnreadNoti(0)
    setCurrentPage(0)
  }

  const hasResourceToLink = (noti: INotification) => {
    const isPayment = noti.message.includes('Payment')
    const hasResourceId = Boolean(
      noti.target_resource_id && noti.target_resource_type,
    )
    return !isPayment && hasResourceId
  }

  const handleClickNotification = (
    noti: INotification,
    onClose: () => void,
  ) => {
    const hasLink = hasResourceToLink(noti)
    if (hasLink) {
      const isNoteMention = noti.message.includes('in a note')
      onClose()
      navigate(
        `/${resourceLinks[noti.target_resource_type]}/${
          noti.target_resource_id
        }${isNoteMention ? `/${PATHNAME.notes}` : ''}`,
      )
    }
    handleMarkRead(noti)
  }

  const renderHeaderList = () => (
    <div className='flex sm:items-center justify-between gap-2 mb-6 flex-col sm:flex-row'>
      <div className='text-base font-medium'>Notifications</div>
      <div className='flex items-center gap-2'>
        {unreadNoti > 0 && (
          <>
            <div
              className='text-primary-900 font-medium cursor-pointer'
              onClick={handleMarkAllRead}
            >
              Mark all as read
            </div>
            <div className='h-4 bg-separation-400 w-px' />
          </>
        )}
        {listNoti.length > 0 && (
          <>
            <div
              className='text-primary-900 font-medium cursor-pointer'
              onClick={handleClearAll}
            >
              Clear all
            </div>
            <div className='h-4 bg-separation-400 w-px' />
          </>
        )}
        <Switch
          className={cx(loadingList && 'opacity-50 pointer-events-none')}
          checked={isShowUnread}
          onChange={(checked: boolean) => {
            setShowUnRead(checked)
          }}
        >
          Only show unread
        </Switch>
      </div>
    </div>
  )

  const renderNotData = () => (
    <div className='flex flex-col gap-4 justify-center items-center min-h-[10rem]'>
      <img src={ImgEmptyData} className='w-56' alt='empty-notification' />
      <p className='text-black-400'>You have no notification at the moment</p>
    </div>
  )

  const renderGroupNoti = (
    key: string,
    list: INotification[],
    onClose: () => void,
  ) => {
    const total = list.length - 1
    return (
      <div>
        <div className='font-semibold text-black-400 mb-1 uppercase'>{key}</div>
        <div className=''>
          {list.map((item, index) => {
            const by_user = item.by_user
            const hasLink = hasResourceToLink(item)
            return (
              <div
                className={cx(
                  'pl-3 pr-6 py-3 relative flex items-center gap-2',
                  hasLink && 'cursor-pointer',
                )}
                key={item.id}
                onClick={() => handleClickNotification(item, onClose)}
              >
                <div>
                  <Avatar
                    src={
                      by_user
                        ? by_user?.avatar_preview_url || by_user?.avatar_url
                        : Logo
                    }
                    hashId={`user-${by_user?.id}`}
                  />
                </div>
                <div>
                  <div className='font-medium mb-2'>{item.message}</div>
                  <div className='text-black-400'>
                    {key === 'today'
                      ? formatDistanceToNow(new Date(item.created_at))
                      : formatDateTime(item.created_at)}
                  </div>
                  {!item.read_at && !isMarkRead && (
                    <span className='w-2 h-2 bg-red-900 rounded-full absolute right-0 top-1/2 -translate-y-1/2' />
                  )}
                  {index < total && (
                    <div className='w-full h-px bg-separation-400 absolute bottom-0 left-0 right-0' />
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
  const renderListNoti = (onClose: () => void) => {
    const listNotiFilter = isShowUnread
      ? listNoti.filter(item => !item.read_at)
      : listNoti
    const listGroupNoti = groupNotifications(listNotiFilter)
    return (
      <Popover.Content
        onClose={onClose}
        className='w-full sm:w-[39rem] !p-0 shadow-dropdown h-auto max-h-[calc(100vh_-_4rem)] custom-scrollbar overflow-auto'
        onScroll={handleScroll}
      >
        <div className={cx('p-4', listAsync.isLoading && 'opacity-50')}>
          {renderHeaderList()}
          {listNotiFilter.length === 0 || (isMarkRead && isShowUnread) ? (
            renderNotData()
          ) : (
            <>
              <div
                className={cx(
                  'flex flex-col gap-4',
                  deleteAsync.isLoading && 'opacity-50',
                )}
              >
                {Object.entries(listGroupNoti).map(([key, value]) => (
                  <div key={key}>{renderGroupNoti(key, value, onClose)}</div>
                ))}
              </div>
            </>
          )}
        </div>
      </Popover.Content>
    )
  }

  const handlePushNotification = (response: {
    data: INotification
    created_at: string
    id: string
  }) => {
    if (response.data) {
      const notification: INotification = {
        ...response.data,
        read_at: null,
        id: response.id,
        created_at: formatDateTime(response?.created_at) || new Date(),
      }
      setUnreadNoti(prev => prev + 1)
      setListNoti(prev => [notification, ...prev])
    }
  }

  useEffect(() => {
    if (auth?.id) {
      handleGetNotification()
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: configPusher?.key || process.env.REACT_APP_PUSHER_APP_KEY,
        cluster:
          configPusher?.cluster || process.env.REACT_APP_PUSHER_APP_CLUSTER,
        forceTLS: true,
        disableStats: true,
        wsHost: configPusher?.wsHost || process.env.REACT_APP_PUSHER_APP_WSHOST,
        wsPort: configPusher?.wsPort || process.env.REACT_APP_PUSHER_APP_WSPORT,
        authorizer: (channel: { name: string }) =>
          authorizeNotification(channel.name),
      })
      window.Echo.private(`App.Models.User.${auth.id}`).notification(
        handlePushNotification,
      )
    }
    return () => {
      if (auth?.id) {
        window.Echo.leave(`App.Models.User.${auth.id}`)
      }
    }
  }, [auth])

  return (
    <Popover
      content={onClose => renderListNoti(onClose)}
      visible={isOpenPopover}
      onVisibleChange={v => setOpenPopover(v)}
      className='items-center flex'
      placement='bottom'
    >
      <div className='relative flex items-center cursor-pointer'>
        <span className='font-icon-notification text-black-800 text-[1.125rem]'></span>
        {unreadNoti > 0 && (
          <Badge
            count={unreadNoti}
            className='absolute -top-2 left-2 px-0 py-0 min-w-[1.175rem] w-[1.175rem] h-[1.175rem] leading[1.175rem] bg-[#c00] cursor-pointer text-[0.5625rem] flex items-center justify-center'
          />
        )}
      </div>
    </Popover>
  )
}
