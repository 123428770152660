import cx from 'classnames'
import { KeyboardEventHandler, useState } from 'react'

import { Input } from '../../../../../components'
import { useAsync } from '../../../../../hooks'
import { editFile } from '../../../../../services/files'
import {
  PERMISSIONS,
  PERMISSIONS_UPDATE,
} from '../../../../../utils/permission'
import { ComponentPermisison } from '../../../../Permission/ComponentPermission'
import { useFileDetailCtx } from '../Context'

export const FileInfoName = () => {
  const { file, onUpdateFile } = useFileDetailCtx()
  const [name, setName] = useState(file.name)
  const [isEditMode, setEditMode] = useState(false)
  const { execute, isLoading } = useAsync({ showNotifOnError: true })

  const handleSaveNote = async () => {
    await execute(editFile(file.id, { name }))
    onUpdateFile({ name })
    setEditMode(false)
  }

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = e => {
    e.key === 'Escape' && setEditMode(false)
    e.key === 'Enter' && !e.shiftKey && handleSaveNote()
  }

  return (
    <>
      <div className='flex items-center mb-3 pb-3 border-b border-b-separation-900 text-body'>
        <div className='inline-flex items-center w-[7.75rem]'>
          <div className='w-[1.875rem] leading-none'>
            <span className='font-icon-image text-black-400 text-[1rem]' />
          </div>
          <span>File Name</span>
        </div>
        <div className='flex-1 break-all'>
          {isEditMode ? (
            <Input
              value={name}
              disabled={isLoading}
              onKeyDown={handleKeyDown}
              onChange={e => setName(e.target.value)}
            />
          ) : (
            <>
              {file.name}
              <ComponentPermisison
                name={PERMISSIONS.FILE}
                type={PERMISSIONS_UPDATE}
              >
                <span
                  className={cx(
                    'cursor-pointer ml-1',
                    isEditMode ? 'font-icon-close' : 'font-icon-edit',
                  )}
                  onClick={() => setEditMode(prev => !prev)}
                />
              </ComponentPermisison>
            </>
          )}
          {}
        </div>
      </div>
    </>
  )
}
