import { FixedType } from 'rc-table/lib/interface'

import { IInvoice } from '../../services/invoices'
import { ResourceNotFoundByFilter } from '../../shared/Resources/NotFound'
import { isMobile } from '../../utils/functions'
import { Table } from '../Table'

interface TableProps {
  loading: boolean
  data: IInvoice[]
}

export const BillingInvoicesTableList = ({ data, loading }: TableProps) => {
  const viewMobile = isMobile()

  const fixedType = viewMobile ? false : ('left' as FixedType)
  const columns = [
    {
      key: 'date',
      title: 'Date',
      width: 150,
      dataIndex: 'date',
      render: (item: Date) =>
        new Date(item).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }),
    },
    {
      key: 'amount',
      title: 'Payment',
      width: 150,
      dataIndex: 'amount',
    },

    {
      key: 'is_paid',
      title: 'Status',
      width: 150,
      render: (isPaid: boolean) =>
        isPaid ? (
          <span className={'bg-green-900 text-white px-2 py-1 rounded-full'}>
            Paid
          </span>
        ) : (
          <span className={'bg-green-900 text-white px-2 py-1 rounded-full'}>
            Pending
          </span>
        ),
    },
    {
      key: 'marker',
      title: (
        <>
          <span className='font-icon-label text-[1rem] mr-2' />
          <span>Invoice</span>
        </>
      ),
      titleClassName: 'justify-center',
      width: 50,
      fixed: fixedType,
      className: 'td-column-fixed',
      dataIndex: 'invoice_hosted_url',
      render: (invoice_hosted_url: string) => (
        <div className='text-center'>
          <a
            href={invoice_hosted_url}
            target='_blank'
            rel='noopener noreferrer'
            className={'text-blue-600'}
          >
            <span className={'font-icon-new_tab mx-1'}></span>
            View
          </a>
        </div>
      ),
    },
  ]

  if (data.length === 0 && !loading) {
    return <ResourceNotFoundByFilter resourceName='INVOICE' />
  }
  return (
    <Table
      id='container-table'
      rowKey='id'
      loading={loading}
      columns={columns}
      data={data}
      className='rounded-lg rounded-b-none bg-white max-w-full rc-table-fixed'
      scroll={{ x: 1400 }}
      showContentHover={true}
    />
  )
}
